import { TRIGGER_VERIFY_TOKEN_REQUEST, getTokens, verifyError } from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import { setObjectInLocalStore } from "../../utils/localStore";
import { VerifyTokenRequestBody } from "app/redux/features/verify/action";
import { clearSessionStore } from "app/utils/sessionStore";

interface VerifyTokenActionPayload {
  type: string;
  payload: {
    address: string;
    body: VerifyTokenRequestBody;
  };
}

function* verifyTokenRequest(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    clearSessionStore();
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(getTokens(response.data));
    if (response.status === 200) {
      setObjectInLocalStore("useraccess", response.data);
    }
  } catch (error: any) {
    yield put(verifyError(error));
  }
}

function* watchVerifyRequest() {
  yield takeLatest(TRIGGER_VERIFY_TOKEN_REQUEST, verifyTokenRequest);
}

export default function* verifySaga() {
  yield all([watchVerifyRequest()]);
}
