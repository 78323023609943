import { getObjectFromSessionStore, setSessionStore } from "app/utils/sessionStore";

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then((registration) => registration.scope)
      .catch(() => {});
  }
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (dt) => {
    const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
    const email = getlookUpResponse?.email;
    const data = dt?.data;

    if (data?.data?.email === email) {
      const notificationBell = document.querySelector(".notification-bell");

      if (notificationBell) {
        setSessionStore("showNotificationDot", true);
        notificationBell.classList.remove("hidden");
      }
    }
  });
}
