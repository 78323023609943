import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const LOANS_PAST_DUES = "LOANS_PAST_DUES";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const loanPastDuesSlice = createSlice({
  name: "loanPastDues",
  initialState,
  reducers: {
    loanPastDuesStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    loanPastDuesSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    loanPastDuesError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetLoanPastDuesState: () => initialState,
  },
});

export const { loanPastDuesStart, loanPastDuesSuccess, loanPastDuesError, resetLoanPastDuesState } =
  loanPastDuesSlice.actions;

export const loanPastDues = (state: RootState) => state.loanPastDues;
export default loanPastDuesSlice.reducer;
