import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_TRANSACTIONS = "GET_CONTACT_TRANSACTIONS";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface contactsResponseType {
  fetchContactList?: InitialStateInterface;
  fetchContactTransactions?: InitialStateInterface;
}

const initialState: contactsResponseType = {
  fetchContactList: { data: {}, error: {}, loading: false },
  fetchContactTransactions: { data: {}, error: {}, loading: false },
};

export const contactsSlice = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    fetchContactListLoading: (state) => {
      state.fetchContactList = { data: {}, error: {}, loading: true };
    },
    fetchContactListSuccess: (state, action: PayloadAction<any>) => {
      state.fetchContactList = { data: action.payload, error: {}, loading: false };
    },
    fetchContactListError: (state, action: PayloadAction<any>) => {
      state.fetchContactList = { data: {}, error: action.payload, loading: false };
    },
    fetchContactTransactionsLoading: (state) => {
      state.fetchContactTransactions = { data: {}, error: {}, loading: true };
    },
    fetchContactTransactionsSuccess: (state, action: PayloadAction<any>) => {
      state.fetchContactTransactions = { data: action.payload, error: {}, loading: false };
    },
    fetchContactTransactionsError: (state, action: PayloadAction<any>) => {
      state.fetchContactTransactions = { data: {}, error: action.payload, loading: false };
    },
    resetContactState: () => initialState,
  },
});

export const {
  fetchContactListLoading,
  fetchContactListSuccess,
  fetchContactListError,
  resetContactState,
  fetchContactTransactionsLoading,
  fetchContactTransactionsSuccess,
  fetchContactTransactionsError,
} = contactsSlice.actions;
export const getContacts = (state: RootState) => state.contacts;

export default contactsSlice.reducer;
