import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_DUPLICATE_PROPOSAL_ID_REQUEST = "TRIGGER_DUPLICATE_PROPOSAL_ID_REQUEST";
export const TRIGGER_DUPLICATE_PROPOSAL_ID_SUCCESS = "TRIGGER_DUPLICATE_PROPOSAL_ID_SUCCESS";
export const TRIGGER_DUPLICATE_PROPOSAL_ID_FAILURE = "TRIGGER_DUPLICATE_PROPOSAL_ID_FAILURE";

interface NewProposalData {
  status: boolean;
  message: string;
  data: {
    proposalType: string;
    proposalNo: string;
    proposalId: string;
  } | null;
}

interface reviewData {
  loading: boolean;
  data: NewProposalData | null;
  error: any;
}

const initialState: reviewData = {
  loading: false,
  data: null,
  error: {},
};

export const reviewDuplicateProposalIdSlice = createSlice({
  name: "reviewDuplicateProposalId",
  initialState,
  reducers: {
    reviewDuplicateProposalIdSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    reviewDuplicateProposalIdError: (state, action: PayloadAction<any>) => {
      state.data = null;
      state.error = action.payload;
      state.loading = false;
    },
    reviewDuplicateProposalIdsOngoing: (state) => {
      state.data = null;
      state.error = {};
      state.loading = true;
    },
    resetsStateProposalId: () => initialState,
  },
});

export const {
  resetsStateProposalId,
  reviewDuplicateProposalIdSuccess,
  reviewDuplicateProposalIdError,
  reviewDuplicateProposalIdsOngoing,
} = reviewDuplicateProposalIdSlice.actions;
export const reviewDuplicateProposalIdContactById = (state: RootState) =>
  state.reviewDuplicateProposalId;

export default reviewDuplicateProposalIdSlice.reducer;
