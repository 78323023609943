import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  generateLRInvoiceStart,
  generateLRInvoiceSuccess,
  generateLRInvoiceError,
  deleteLRInvoiceStart,
  deleteLRInvoiceSuccess,
  deleteLRInvoiceError,
  GENERATE_LR_INVOICE,
  DELETE_LR_INVOICE,
} from "app/redux";

function* deleteloanReviewInvoice(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address, key, requestParams, queryParams },
    } = actions;
    yield put(deleteLRInvoiceStart());
    const response = yield call(() => makeRequest(address).delete(key, requestParams, queryParams));
    yield put(deleteLRInvoiceSuccess(response.data));
  } catch (error: any) {
    yield put(deleteLRInvoiceError(error));
  }
}

function* putLoanReviewInvoiceRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(generateLRInvoiceStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(generateLRInvoiceSuccess(response.data));
  } catch (error: any) {
    yield put(generateLRInvoiceError(error));
  }
}

function* watchLoanReviewInvoiceSummary() {
  yield takeLatest(GENERATE_LR_INVOICE, putLoanReviewInvoiceRequest);
  yield takeLatest(DELETE_LR_INVOICE, deleteloanReviewInvoice);
}

export default function* watchLoanReviewInvoiceSummarySaga() {
  yield all([watchLoanReviewInvoiceSummary()]);
}
