import {
  fetchSubscriptionCancelStart,
  fetchSubscriptionCancelSuccess,
  fetchSubscriptionCancelFailure,
  TRIGGER_PUT_SUBSCRIPTION_CANCEL,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* subscriptionCancelRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(fetchSubscriptionCancelStart());
    const response = yield call(() =>
      makeRequest(address).patch(body, key, requestParams, queryParams),
    );
    yield put(fetchSubscriptionCancelSuccess(response.data));
  } catch (error: any) {
    yield put(fetchSubscriptionCancelFailure(error));
  }
}
function* watchSubscriptionCancelSagas() {
  yield takeLatest(TRIGGER_PUT_SUBSCRIPTION_CANCEL, subscriptionCancelRequest);
}

export default function* subscriptionCancelSagas() {
  yield all([watchSubscriptionCancelSagas()]);
}
