import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_TEAM_ASSISTANT_DATA = "GET_TEAM_ASSISTANT_DATA";
export const REMOVE_TEAM_ASSISTANT_DATA = "REMOVE_TEAM_ASSISTANT_DATA";

interface TeamAssistantResponse {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  getTeamAssistantResponse?: TeamAssistantResponse;
  removeTeamAssistantResponse?: TeamAssistantResponse;
}

const initialState: responseType = {
  getTeamAssistantResponse: { data: {}, error: {}, loading: false },
  removeTeamAssistantResponse: { data: {}, error: {}, loading: false },
};

export const teamAssistantSlice = createSlice({
  name: "teamAssistant",
  initialState,
  reducers: {
    getTeamAssistantStart: (state) => {
      state.getTeamAssistantResponse = { data: {}, error: {}, loading: true };
    },
    getTeamAssistantSuccess: (state, action: PayloadAction<responseType>) => {
      state.getTeamAssistantResponse = { data: action.payload, error: {}, loading: false };
    },
    getTeamAssistantError: (state, action: PayloadAction<responseType>) => {
      state.getTeamAssistantResponse = { data: {}, error: action.payload, loading: false };
    },
    resetGetTeamAssistant: (state) => {
      state.getTeamAssistantResponse = { data: {}, error: {}, loading: false };
    },
    removeTeamAssistantStart: (state) => {
      state.removeTeamAssistantResponse = { data: {}, error: {}, loading: true };
    },
    removeTeamAssistantSuccess: (state, action: PayloadAction<responseType>) => {
      state.removeTeamAssistantResponse = { data: action.payload, error: {}, loading: false };
    },
    removeTeamAssistantError: (state, action: PayloadAction<responseType>) => {
      state.removeTeamAssistantResponse = { data: {}, error: action.payload, loading: false };
    },
    resetRemoveTeamAssistant: (state) => {
      state.removeTeamAssistantResponse = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  getTeamAssistantError,
  getTeamAssistantStart,
  getTeamAssistantSuccess,
  resetGetTeamAssistant,
  removeTeamAssistantError,
  removeTeamAssistantStart,
  removeTeamAssistantSuccess,
} = teamAssistantSlice.actions;

export const teamAssistantLedgerStore = (state: RootState) => state.teamAssistantSaga;

export default teamAssistantSlice.reducer;
