import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const AGENT_PROFILE_LOOKUP = "AGENT_PROFILE_LOOKUP";

interface agentProfileLookup {
  isLoading: boolean;
  data: any;
  error: any;
}

const initialState: agentProfileLookup = {
  isLoading: false,
  data: {},
  error: {},
};

const agentProfileLookupSlice = createSlice({
  name: "agentProfileLookup",
  initialState,
  reducers: {
    agentProfileLookupStart: (state: agentProfileLookup) => {
      state.isLoading = true;
      state.data = {};
      state.error = {};
    },
    agentProfileLookupSuccess: (state: agentProfileLookup, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    },
    agentProfileLookupFailure: (state: agentProfileLookup, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    },
    resetAgentProfileLookup: (state: agentProfileLookup) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
});

export const {
  agentProfileLookupStart,
  agentProfileLookupSuccess,
  agentProfileLookupFailure,
  resetAgentProfileLookup,
} = agentProfileLookupSlice.actions;

export const agentProfileLookUP = (state: RootState) => state.agentProfileLookup;

export default agentProfileLookupSlice.reducer;
