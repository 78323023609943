import {
  VALIDATE_INVITE,
  validateInviteError,
  validateInviteStart,
  validateInviteSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* validateInviteRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(validateInviteStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(validateInviteSuccess(response.data));
  } catch (error: any) {
    yield put(validateInviteError(error));
  }
}

function* watchvalidateInviteSaga() {
  yield takeLatest(VALIDATE_INVITE, validateInviteRequest);
}

export default function* validateInviteSaga() {
  yield all([watchvalidateInviteSaga()]);
}
