import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PLAN_UPGRADE_REQUEST = "TRIGGER_PLAN_UPGRADE_REQUEST";

interface PlansInterface {
  upgradeData: any;
  upgradeLoading: boolean;
  upgradeError: string | null;
}

const initialState: PlansInterface = {
  upgradeData: [],
  upgradeLoading: false,
  upgradeError: null,
};

export const plansUpgradeListSlice = createSlice({
  name: "plansUpgrade",
  initialState,
  reducers: {
    fetchPlanDataFailure: (state, action: PayloadAction<any>) => {
      state.upgradeLoading = false;
      state.upgradeError = action.payload;
    },
    fetchPlanDataSuccess: (state, action: PayloadAction<any>) => {
      state.upgradeLoading = false;
      state.upgradeData = action.payload;
    },
    fetchPlanDataStart: (state) => {
      state.upgradeLoading = true;
      state.upgradeError = null;
    },
    resetPlanData: () => initialState,
  },
});

export const { resetPlanData, fetchPlanDataFailure, fetchPlanDataSuccess, fetchPlanDataStart } =
  plansUpgradeListSlice.actions;

export const getUpgradePlans = (state: RootState) => state.getUpgradePlanStatus;

export default plansUpgradeListSlice.reducer;
