import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PUT_VIEW_POLICY_DETAILS = "PUT_VIEW_POLICY_DETAILS";
export const PUT_POLICY_DETAILS = "PUT_POLICY_DETAILS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const putViewPolicyDetailsSlice = createSlice({
  name: "putViewPolicyDetails",
  initialState,
  reducers: {
    putViewPolicyDetailsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    putViewPolicyDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    putViewPolicyDetailsError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPutViewPolicyDetailsState: () => initialState,
  },
});

export const {
  putViewPolicyDetailsStart,
  putViewPolicyDetailsSuccess,
  putViewPolicyDetailsError,
  resetPutViewPolicyDetailsState,
} = putViewPolicyDetailsSlice.actions;

export const putViewPolicyDetails = (state: RootState) => state.putViewPolicyDetails;
export default putViewPolicyDetailsSlice.reducer;
