import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};
export const removeTeamMemberSlice = createSlice({
  name: "removeTeamMember",
  initialState,
  reducers: {
    removeTeamMemberStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    removeTeamMemberSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    removeTeamMemberError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetRemoveTeamMemberState: () => initialState,
  },
});
export const {
  removeTeamMemberStart,
  removeTeamMemberSuccess,
  removeTeamMemberError,
  resetRemoveTeamMemberState,
} = removeTeamMemberSlice.actions;
export const removeTeamMember = (state: RootState) => state.removeTeamMember;
export default removeTeamMemberSlice.reducer;
