import { Image } from "primereact/image";
import { ValidateResult } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { CardFieldsInterface, DATEFORMAT, THEME, ProposalTypes, CUSTOM } from "app/utils/types";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { mapObjIndexed, pickBy, isEmpty, is, pipe, trim } from "ramda";
import { formatDateFromJSDate, formatDateToSubmit } from "./luxon";
import HelloSign from "hellosign-embedded";
import { getObjectFromSessionStore, getSessionStore, removeSessionStore } from "./sessionStore";
import {
  resetgetLoanPlanProjectionState,
  resetPlanAdminSplitDollarState,
  resetPlanSplitDollarState,
  resetpostCSVDataState,
} from "app/redux";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import * as R from "ramda";
import i18n from "i18next";
import { getLocalStore, getObjectFromLocalStore } from "./localStore";
import axios from "axios";
// import IAPPLogoDarkIcon from "../assets/IAPPLogoDarkIcon.svg";
import { contactProfileLookUP } from "app/redux/features/contact-profile-lookup";

export const IAPPLogo = (props: { classAdd?: string }) => {
  const { classAdd } = props;
  const branding = useSelector((state: RootState) => state?.contactBranding);
  const { data: lookupData } = useSelector(contactProfileLookUP);

  const IAPPLogoAWS =
    (branding.primaryLogo === "" || !branding.primaryLogo) &&
    !lookupData?.branding?.hasActiveElitePlan
      ? branding.theme === THEME.DARK
        ? process.env.REACT_APP_ENVIRONMENT === "PROD"
          ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
          : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png`
        : process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`
      : branding.primaryLogo;

  return (
    <div className={`${classAdd || "absolute flex align-items-center my-2 gap-2 top-0"}`}>
      <div>
        <Image src={IAPPLogoAWS} alt="Image" width="180" />
      </div>
    </div>
  );
};

export const IAPPDarkLogo = (props: { classAdd?: string }) => {
  const { classAdd } = props;
  return (
    <div className={`flex align-items-center my-2 gap-2 absolute top-0 ${classAdd || "absolute"}`}>
      <div>
        <Image
          src={
            process.env.REACT_APP_ENVIRONMENT === "PROD"
              ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
              : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png"`
          }
          alt="Image"
          width="100"
          height="100"
        />
      </div>
    </div>
  );
};

export const FormBody = () => {};

export const validatePassword = (value: any): ValidateResult => {
  const hasLower = /[a-z]/.test(value);
  const hasUpper = /[A-Z]/.test(value);
  const hasNumberValue = /\d/.test(value);
  const hasSpeciaCharacter = /^(?=.*[!@=#$%^&*()_+{}\\[\]:;<>,.?~`'"|\\/\\-])[^\s]+$/.test(value);
  const hasMinimumLength = value.length >= 8;
  const returnObject = JSON.stringify({
    hasLower,
    hasUpper,
    hasSpeciaCharacter,
    hasNumberValue,
    hasMinimumLength,
  });
  return returnObject;
};
export const convertCamelCaseToSentenceCase = (camelCaseString: any) =>
  camelCaseString?.replace(/([A-Z])/g, " $1")?.replace(/^./, (str: string) => str.toUpperCase());

export function convertStringNumberToTwoDecimal(value: any, isCurrency = true, currency = "$") {
  const twoDecimalPointString = isCurrency
    ? `${currency}${Number(value).toFixed()}`
    : Number(value).toFixed(0);

  return twoDecimalPointString;
}

export const capitalizeFirstLetterOfStr = (str: any) => str.charAt(0).toUpperCase() + str.slice(1);

export const convertDataToFields = (
  data: CardFieldsInterface | any,
  fieldsMapping: { [key: string]: string }[],
) => {
  const fields = fieldsMapping.map(({ key, label }: { [key: string]: string }) => ({
    id: uuidv4(),
    label:
      label || key === "positionTitle"
        ? convertCamelCaseToSentenceCase("title")
        : convertCamelCaseToSentenceCase(key),
    value: data[key],
  }));
  return fields;
};

export const capitalizeWords = R.pipe(
  R.split(" "), // Split the string into words
  R.map(capitalizeFirstLetterOfStr), // Convert each word to uppercase
  R.join(" "), // Join the words back into a string
);

export function prettifyData(data: any[]) {
  const isStringEmpty = pipe(trim, isEmpty);
  const prettyData = data?.map((item: any) => ({
    ...item,
    contactName: isStringEmpty(item.contactName) ? "-" : item.contactName,
    email: isEmpty(item?.email) ? "-" : item?.email,
  }));
  return prettyData;
}

export function prettifyDataForLoans(data: any[]) {
  const isStringEmpty = pipe(trim, isEmpty);
  const prettyData = data?.map((item: any) => ({
    ...item,
    loanContactName: isStringEmpty(item.loanContactName) ? "-" : item.loanContactName,
  }));
  return prettyData;
}

export const capitalizeFirstLetter = (string?: string) =>
  `${string?.charAt(0).toUpperCase()}${string?.slice(1)}`;

export const formatUnits = (loanBalance: number, currency?: any) => {
  const loanBalanceData = typeof loanBalance === "string" ? Number(loanBalance) : loanBalance;
  const balance = loanBalanceData || 0;
  const roundedValue = balance?.toFixed(0);
  const formattedBalance = Number(roundedValue)?.toLocaleString();
  return currency === "$" ? `$${formattedBalance}` : formattedBalance;
};

export const getFieldFromObject = (object: any, field: any) =>
  field.split(".").reduce((obj: any, key: any) => obj?.[key], object);

export const combinedString = (messages: string[] | string) =>
  Array.isArray(messages) ? messages.map((message: any) => `${message}. `).join("\n") : messages;

export const feetToInches = (feet: number, inches: number) => feet * 12 + inches;

export const feetToCentimeters = (feet: number, inches: number) => {
  const centimeters = feet * 30.48 + inches * 2.54;
  return centimeters;
};

export const cmsToFeet = (cms: string | number) => {
  const centimeters = Number(cms);
  const inches = centimeters * 0.393701;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);
  const heightInFeet = `${feet}'${remainingInches}"`;
  return heightInFeet;
};

export function centimetersToFeetAndInches(centimeters: any) {
  // Convert centimeters to inches
  const cmToInches = centimeters * 0.393701;

  // Convert inches to feet and remaining inches
  const feet = Math.floor(cmToInches / 12);
  const inches = Math.round(cmToInches % 12);

  return { feet, inches };
}

export const extractFilters = (data: any) => {
  const filters: any = {};
  // Loop through each column
  // eslint-disable-next-line no-restricted-syntax
  for (const column of data.column) {
    const { field, filters: columnFilters } = column;

    // Check if the column has filters
    if (columnFilters) {
      const { value } = columnFilters;

      // Add the filter value to the filters object
      if (value !== undefined && value !== "") {
        filters[field as string] = value;
      }
    }
  }

  return filters;
};
export const debounce = (callback: any, delay: number) => {
  let timeoutId: any;

  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
export function objectToQueryString(obj: any) {
  const queryParts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = Array.isArray(obj[key][0])
        ? encodeURIComponent(JSON.stringify(obj[key][0]))
        : typeof obj[key][0] === "number"
        ? obj[key]
        : encodeURIComponent(obj[key]); // 2023-05-23,2023-05-30
      if (value && Array.isArray(obj[key][0]) && obj[key][0].length) {
        queryParts.push(`${key}=${value}`);
      } else if (value && !Array.isArray(obj[key][0])) {
        // incase on integer array
        is(Number, obj[key][0])
          ? queryParts.push(`${key}=[${value}]`)
          : queryParts.push(`${key}=["${value.toLowerCase()}"]`);
      }
    }
  }
  return queryParts.join("&");
}

export function objectToQueryStringWithLowerCase(obj: any) {
  const queryParts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = Array.isArray(obj[key][0])
        ? encodeURIComponent(JSON.stringify(obj[key][0]))
        : typeof obj[key][0] === "number"
        ? obj[key]
        : encodeURIComponent(obj[key]); // 2023-05-23,2023-05-30
      if (value && Array.isArray(obj[key][0]) && obj[key][0].length) {
        queryParts.push(`${key}=${value}`);
      } else if (value && !Array.isArray(obj[key][0])) {
        // incase on integer array
        is(Number, obj[key][0])
          ? queryParts.push(`${key}=[${value}]`)
          : queryParts.push(`${key}=["${value}"]`);
      }
    }
  }
  return queryParts.join("&");
}

export const updateFiltersValue = (items: any, field: any, value: string | number) =>
  items.map((item: any) => {
    if (item.field === field) {
      return {
        ...item,
        filters: {
          ...item.filters,
          value,
        },
      };
    }
    return item;
  });

export const resetFiltersValue = (items: any) =>
  items.map((item: any) => ({
    ...item,
    filters: {
      ...item.filters,
      value: "",
    },
  }));

export const convertFilters = (filters: any, propName: any) =>
  mapObjIndexed((val: any[]) => [val[propName]], filters);

export const truncateString = (str: any, maxLength: any) => `${str.slice(0, maxLength)}...`;
export const removeEmptyKeys = (obj: any): object => pickBy((value) => !isEmpty(value), obj);

export function capitalizeNames(inputString: any) {
  function capitalizeFirstLetterOfArray(name: any) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  const namesArray = inputString?.split(",");
  const capitalizedNamesArray = namesArray?.map((name: any) =>
    capitalizeFirstLetterOfArray(name.trim()),
  );
  const capitalizedNamesString = capitalizedNamesArray?.join(", ");

  return capitalizedNamesString;
}

export const getFirstNameAbbreviation = (name: string) => {
  const words = name.trim().split(" ");

  const abbreviation = words
    .filter((word: string) => word.length > 0)
    .map((word: string) => word[0].toUpperCase())
    .join("");

  return abbreviation;
};

export const truncateEmailBeforeAtSymbol = (email: string) => {
  if (!email || typeof email !== "string") {
    return "";
  }

  const atIndex = email.indexOf("@");
  if (atIndex === -1 || atIndex <= 16) {
    return email;
  }

  return `${email.slice(0, 16)}...${email.slice(atIndex)}`;
};

export const getEncodedURI = (obj: any) => {
  const filterAgentsClone = { ...obj };
  if ("team" in filterAgentsClone && !isEmpty(filterAgentsClone.team[0])) {
    filterAgentsClone.team = filterAgentsClone.team.map((item: any) => Number(item));
  }
  if ("invitationDate" in filterAgentsClone && !isEmpty(filterAgentsClone.invitationDate[0])) {
    const dateVal = filterAgentsClone.invitationDate[0];
    const formattedFilterProposal = dateVal
      ? [...dateVal.map((item: string) => formatDateFromJSDate(item, "yyyy-MM-dd"))]
      : [];
    filterAgentsClone.invitationDate[0] = formattedFilterProposal;
  }
  return objectToQueryString(filterAgentsClone);
};

export const checkForAnySearchParameter = (obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => acc || obj[key].some((agent: string) => agent?.trim().length > 0),
    false,
  );

export const openHelloSignModal = (
  url: string,
  onError: () => void,
  onSign: () => void,
  onClose?: () => void,
) => {
  const client = new HelloSign();
  const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
  // const CLIENT_ID = process.env.HELLO_SIGN_CLIENT_KEY;
  const CLIENT_ID = getLookUpResponse.helloSignClientKey;
  if (!CLIENT_ID && onError) {
    onError();
  }
  if (CLIENT_ID) {
    client.open(url, {
      clientId: CLIENT_ID,
      skipDomainVerification: true,
    });
  }
  if (onSign) {
    client.on("sign", onSign);
  }
  if (onClose) {
    client.on("close", onClose);
  }
};

// export const getOrdinal = (day) => {
//   const lastDigit = day % 10;
//   return lastDigit === 1 ? "st" : lastDigit === 2 ? "nd" : lastDigit === 3 ? "rd" : "th";
// };
export const resetCreateProposalStates = (dispatch: Dispatch<AnyAction>) => {
  removeSessionStore("fromRequestForProposal");
  removeSessionStore("proposalID");
  removeSessionStore("CompanyNameforUpload");
  removeSessionStore("prouctNameforUpload");
  dispatch(resetpostCSVDataState());
  dispatch(resetPlanAdminSplitDollarState());
  dispatch(resetPlanSplitDollarState());
  dispatch(resetgetLoanPlanProjectionState());
};

export const capitalizeFirstLetterOfEveryWordInaString = (sentence: string) =>
  sentence?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const formatFormData = ({
  formData,
  formDataFormat,
}: {
  formData: any;
  formDataFormat: any[];
}) => {
  if (!formData || !formDataFormat) return [];
  return formDataFormat
    .map((items: any) => {
      const cardApi: any = formData[items.cardType];
      if (Array.isArray(cardApi)) {
        return cardApi.map((api, i: number) => {
          const keysArray = Object.entries(api).map(([key]) => ({ key }));
          const header = {
            ...items.header,
          };
          if (header && cardApi.length > 1) {
            header.value = `${header.value} ${i + 1}`;
          }
          return {
            ...items,
            header,
            fields: convertDataToFields(api, keysArray),
          };
        });
      }
      const keysArray = Object.entries(cardApi).map(([key]) => ({ key }));
      return {
        ...items,
        fields: convertDataToFields(cardApi, keysArray),
      };
    })
    .flat();
};

export const convertCommaSeparated = (data: any) => {
  const fixingData: any = Number(data).toFixed();
  return data ? `$${Number(fixingData).toLocaleString()}` : `$ 0`;
};

export const convertCommaSeparatedWODollar = (data: any) => {
  const fixingData: any = Number(data).toFixed();
  return data ? `${Number(fixingData).toLocaleString()}` : `0`;
};

export const formatBuySellDetails = (bsData: any, proposalType: ProposalTypes) => {
  if (!bsData) return {};
  const {
    id,
    basicDetails: { entityName, state },
    planDetails: {
      businessValuation,
      currentBuySellAgreement,
      estimatedLastDate,
      fullyFundedOnDeath,
      fullyFundedOnDisability,
      lifeInsuranceType,
      annualRenewableTerm,
      meetEstimatedValuation,
      needBusinessValuation,
      note,
      updateBuySellAgreement,
    },
    shareholders,
  } = bsData;

  const formattedData = {
    id,
    basicDetails: {
      "Proposal type": proposalType,
      "Legal Name of Entity": entityName,
      state,
      "No of Shareholders": shareholders.length,
    },
    planDetails: {
      "Estimated Business Valuation": businessValuation
        ? convertCommaSeparated(businessValuation)
        : businessValuation,
      "Estimated Last Date Business Valuation Completed": estimatedLastDate
        ? formatDateToSubmit(estimatedLastDate, DATEFORMAT.TYPE3)
        : estimatedLastDate,
      "Need to complete a business valuation?": needBusinessValuation,
      "Buy Sell Agreement in place": currentBuySellAgreement,
      "Wanting to update the Buy Sell Agreement?": updateBuySellAgreement,
      "Funding in the event of death?":
        Number(fullyFundedOnDeath) === 100 ? "Full" : `${fullyFundedOnDeath}%`,
      "Want enough life insurance to meet estimated valuation in 5 years?": meetEstimatedValuation,
      "Life Insurance Utilization": lifeInsuranceType,
      ...(annualRenewableTerm &&
        lifeInsuranceType !== "Permanent" &&
        Object.keys(annualRenewableTerm).length > 0 && {
          "Term Length": annualRenewableTerm,
        }),
      "Funding in the event of disability?":
        fullyFundedOnDisability !== "No" ? `${fullyFundedOnDisability}` : "No",
      note,
    },
    shareholders: shareholders.map((shareholderObj: any) => {
      const {
        name,
        ownershipPercentage,
        dob,
        gender,
        nicotineTobaccoUsage,
        occupation,
        participateInActivities,
      } = shareholderObj;
      return {
        "Shareholder Name": name,
        "Date of Birth": formatDateToSubmit(dob, DATEFORMAT.TYPE3),
        Gender: gender,
        Ownership: `${ownershipPercentage}%`,
        "Nicotine or Tobacco Usage": nicotineTobaccoUsage,
        Occupation: capitalizeFirstLetter(occupation),
        "Participate In Activities": participateInActivities.join(", "),
      };
    }),
  };

  return formattedData;
};

export const getProvisionDataToPost = (data: any) => {
  if (isEmpty(data)) return [];
  const proposalId = getSessionStore("proposalID");
  const formattedData = Object.entries(data).reduce((acc: any, curr: any) => {
    const [key, val = ""] = curr;
    if (val && !isEmpty(val) && typeof val === "object") {
      const { payout, condition } = val;
      const formattedPayout = payout === CUSTOM ? data[`${key}percentage`] : payout;
      const formattedCondition = payout === CUSTOM ? data[`${key}condition`] : condition;
      acc.push({
        name: capitalizeFirstLetter(key),
        payout: Number(formattedPayout),
        condition: formattedCondition,
        proposalId,
      });
    }
    return acc;
  }, []);
  return {
    provisions: formattedData,
  };
};

export const transformString = (inputString: string, separator: string = " "): string => {
  const separatedWords = inputString.split(/(?=[A-Z])/);
  return separatedWords
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(separator);
};

export const toCamelCase = (str: string): string =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());

// add userType specific proposal statuses
const proposalStatusByUserType = {
  agent: {
    signed: "Awaiting IAPP Approval",
    uploaded: "Awaiting IAPP Approval",
    received: "Review & Sign",
    feedback: "Awaiting Updated Proposal",
    requested: "Proposal Requested",
    draft: "Proposal Requested",
    created: "Proposal Requested",
  },
  contact: {
    signed: "Awaiting IAPP Approval",
    uploaded: "Awaiting IAPP Approval",
    sent: "Review & Sign",
    feedback: "Awaiting Updated Proposal",
    requested: "Awaiting Proposal",
    draft: "Awaiting Proposal",
    received: "Awaiting Advisor Approval",
  },
};

const proposalStatus = {
  requested: "Proposal Requested",
  revoked: "Proposal Revoked",
  draft: "Initial Draft",
  created: "Initial Draft",
  received: "Awaiting Advisor Approval",
  sent: "Awaiting Client Approval",
  downloaded: "Advisor Downloaded Proposal",
  feedback: "Change Request",
  reviewed: "Advisor Reviewed Proposal",
  signed: "Finalize Proposal",
  uploaded: "Finalize Proposal",
  new_signature_requested: "New Signatures Needed",
  approved: "Approved",
  inactive: "Inactive",
  pending_drafts: "Pending Draft",
  pending_revoked: "Pending Revoked",
};

export const proposalStatuses = () => ({
  ...proposalStatus,
  ...(proposalStatusByUserType[
    getObjectFromLocalStore("useraccess")?.userType as keyof typeof proposalStatusByUserType
  ] ?? {}),
});

function pad(number: any) {
  return (number < 10 ? "0" : "") + number;
}

export function getTime(timestampString: any) {
  const date = new Date(timestampString);

  // Get the UTC components
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  // Format the time in 24-hour and minute format
  const timeString = `${pad(utcHours)}:${pad(utcMinutes)}`;
  return timeString;
}

export function ValidatePhoneNumber(value: any) {
  const lastTenDigits = String(value).substring(value.length - 10);
  if (lastTenDigits === "" || lastTenDigits.trim() === "+") return i18n.t("general.phoneRequired");
  if (!/^\d+$/.test(lastTenDigits)) return i18n.t("general.phoneValidation.message");
  return true;
}

export const getAccessForPermission = (
  resourceName: any,
  accessType: "readAccess" | "writeAccess" = "writeAccess",
) => {
  const superAdminUser = getLocalStore("useraccess");
  if (superAdminUser?.isSuperAdmin) return false;
  const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
  const roleData = getlookUpResponse?.permissionData
    ?.find((item: any) => item.roleName === getlookUpResponse?.role?.roleName)
    ?.permissions?.find((permissions: any) => permissions?.resource?.name === resourceName);
  if (roleData) {
    return !roleData[accessType];
  }
  return false;
};

export const getFileName = (path: string) => {
  const idx = path.lastIndexOf("/");
  const fileName = path.substring(idx + 1);
  const jdx = fileName?.lastIndexOf("_");
  const ext = fileName?.split(".")?.pop();
  return fileName.substring(0, jdx) + (ext ? `.${ext}` : "");
};

export function convertToCSV(objArray: any) {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  // Add headers

  const headers = Object.keys(array[0]);
  // eslint-disable-next-line
  str += headers.join(",") + "\r\n";

  // Add data
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let j = 0; j < headers.length; j++) {
      if (line !== "") line += ",";

      // line += array[i][headers[j]];
      line += `"${array[i][headers[j]]}"`;
    }
    // eslint-disable-next-line
    str += line + "\r\n";
  }

  return str;
}

export function downloadCSV(data: any, filename: any) {
  const csv = convertToCSV(data);

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const CHAT_API_URL = "https://zealert-api.westus2.cloudapp.azure.com/core/v1";

export const getChatConversation = (currUserId: string, receiverUserId: string) =>
  axios.post(`${CHAT_API_URL}/workspace/conversation`, {
    conversation: {
      title: "",
      created_by: currUserId,
    },
    participants: [
      {
        participant_id: currUserId,
      },
      {
        participant_id: receiverUserId,
      },
    ],
  });

export const sendMessage = (
  conversationId: number,
  currUserId: string,
  message?: string,
  files?: any[],
) =>
  axios.post(`${CHAT_API_URL}/messages`, {
    conversation_id: conversationId,
    sender: currUserId,
    message: message ?? "",
    data: {
      files: files ?? [],
    },
  });

export const calculateAge = (birthday: any) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const WAIVE_OFF_DAY_COUNT = 0; // set to 4 once qa testing is done

export function formatPhoneNumber(phoneNumberString: string) {
  if (phoneNumberString === "NA" || Boolean(phoneNumberString) === false) {
    return "NA";
  }
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(?:\d{1,2})?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const countryCode = cleaned.slice(0, -10) || "1";
    const formattedNumber = `+${countryCode} (${match[1]}) ${match[2]}-${match[3]}`;
    return formattedNumber;
  }
  return null;
}

export function invertObject(obj: { [key: string]: string }): { [key: string]: string | string[] } {
  const newObj: { [key: string]: string | string[] } = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] in newObj) {
      const prevVal = Array.isArray(newObj[obj[key]]) ? newObj[obj[key]] : [newObj[obj[key]]];
      newObj[obj[key]] = [...(prevVal as string[]), key];
    } else {
      newObj[obj[key]] = key;
    }
  });

  return newObj;
}

export function checkObjForZeroValues(data: any, keysToCheck: any[] = []) {
  if (data === undefined) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keysToCheck) {
    if (Number(data[key]) === 0) {
      return false;
    }
  }

  return true;
}

export const getBlobUrl = (url: string) => {
  const uri = url?.split(",");
  if (!uri || uri?.length < 2) {
    return url;
  }
  const byteCharacters = atob(uri[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: "application/pdf" });

  return URL.createObjectURL(blob);
};

export function HeaderSvgBgSring(bgColor = "#85B2F9") {
  const svgString = `<svg width="1440" height="180" viewBox="0 0 1440 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame 811498" clip-path="url(#clip0_254_234)">
<rect width="1440" height="180" fill="${bgColor}"/>
<g id="Group 811497">
<g id="Mask group">
<mask id="mask0_254_234" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="256">
<rect id="Image" width="1440" height="256" fill="#F1F1F1"/>
</mask>
<g mask="url(#mask0_254_234)">
<g id="Group 1832">
<g id="Ellipse 18" filter="url(#filter0_d_254_234)">
<circle cx="730.963" cy="730.963" r="730.963" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 1334.6 303.812)" fill="url(#paint0_linear_254_234)" shape-rendering="crispEdges"/>
<circle cx="730.963" cy="730.963" r="730.588" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 1334.6 303.812)" stroke="url(#paint1_linear_254_234)" stroke-width="0.75" shape-rendering="crispEdges"/>
</g>
<g id="Ellipse 21" filter="url(#filter1_d_254_234)">
<circle cx="971.646" cy="971.646" r="971.646" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 1669 303.812)" fill="url(#paint2_linear_254_234)" shape-rendering="crispEdges"/>
<circle cx="971.646" cy="971.646" r="971.271" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 1669 303.812)" stroke="url(#paint3_linear_254_234)" stroke-width="0.75" shape-rendering="crispEdges"/>
</g>
<g id="Ellipse 19" filter="url(#filter2_d_254_234)">
<circle cx="476.715" cy="476.715" r="476.715" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 981.344 303.813)" fill="url(#paint4_linear_254_234)" shape-rendering="crispEdges"/>
<circle cx="476.715" cy="476.715" r="476.34" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 981.344 303.813)" stroke="url(#paint5_linear_254_234)" stroke-width="0.75" shape-rendering="crispEdges"/>
</g>
<g id="Ellipse 20" opacity="0.5" filter="url(#filter3_d_254_234)">
<circle cx="233.061" cy="233.061" r="233.061" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 642.812 303.813)" fill="url(#paint6_linear_254_234)" shape-rendering="crispEdges"/>
<circle cx="233.061" cy="233.061" r="232.686" transform="matrix(-0.694698 0.719302 -0.694698 -0.719302 642.812 303.813)" stroke="url(#paint7_linear_254_234)" stroke-width="0.75" shape-rendering="crispEdges"/>
</g>
</g>
</g>
</g>
</g>
</g>
<defs>
<filter id="filter0_d_254_234" x="-413.135" y="-450.757" width="1464.27" height="1515.14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_254_234"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_254_234" result="shape"/>
</filter>
<filter id="filter1_d_254_234" x="-649.594" y="-695.591" width="1937.19" height="2004.81" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_254_234"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_254_234" result="shape"/>
</filter>
<filter id="filter2_d_254_234" x="-163.35" y="-192.123" width="964.698" height="997.873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_254_234"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_254_234" result="shape"/>
</filter>
<filter id="filter3_d_254_234" x="76.0277" y="55.733" width="485.941" height="502.16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0 0.620833 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_254_234"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_254_234" result="shape"/>
</filter>
<linearGradient id="paint0_linear_254_234" x1="730.963" y1="-119.179" x2="730.963" y2="677.179" gradientUnits="userSpaceOnUse">
<stop offset="0.00508045" stop-color="white"/>
<stop offset="1" stop-color="#F5F5F5" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_254_234" x1="752.812" y1="-15.8905" x2="709.113" y2="959.388" gradientUnits="userSpaceOnUse">
<stop stop-color="#ECECEC"/>
<stop offset="1" stop-color="#F9F9F9"/>
</linearGradient>
<linearGradient id="paint2_linear_254_234" x1="971.646" y1="-158.42" x2="971.646" y2="900.153" gradientUnits="userSpaceOnUse">
<stop offset="0.00508045" stop-color="white"/>
<stop offset="1" stop-color="#F5F5F5" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_254_234" x1="1000.69" y1="-21.1227" x2="942.602" y2="1275.28" gradientUnits="userSpaceOnUse">
<stop stop-color="#ECECEC"/>
<stop offset="1" stop-color="#F9F9F9"/>
</linearGradient>
<linearGradient id="paint4_linear_254_234" x1="476.715" y1="-77.7252" x2="476.715" y2="441.639" gradientUnits="userSpaceOnUse">
<stop offset="0.00508045" stop-color="white"/>
<stop offset="1" stop-color="#F5F5F5" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_254_234" x1="490.964" y1="-10.3634" x2="462.465" y2="625.688" gradientUnits="userSpaceOnUse">
<stop stop-color="#ECECEC"/>
<stop offset="1" stop-color="#F9F9F9"/>
</linearGradient>
<linearGradient id="paint6_linear_254_234" x1="233.061" y1="-37.999" x2="233.061" y2="215.912" gradientUnits="userSpaceOnUse">
<stop offset="0.00508045" stop-color="white"/>
<stop offset="1" stop-color="#F5F5F5" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_254_234" x1="240.027" y1="-5.06653" x2="233.061" y2="256.762" gradientUnits="userSpaceOnUse">
<stop stop-color="#D5D5D5"/>
<stop offset="1" stop-color="#F9F9F9"/>
</linearGradient>
<clipPath id="clip0_254_234">
<rect width="1440" height="180" fill="white"/>
</clipPath>
</defs>
</svg>
`;

  return svgString;
}

export function updatePanelItems(items: any, response: any) {
  const hidePending = response.pendingApproved === 0;
  const hideProposals = response.proposals === 0;
  const updatedItems = items.map((item: any) => ({
    ...item,
    hide: item.name === "Pending" ? hidePending : item.name === "Proposals" ? hideProposals : false,
  }));

  return updatedItems;
}

export function hexToRgb(hex: string) {
  // Remove the hash (#) if it's included
  hex = hex.replace(/^#/, "");

  // Parse the hex value into red, green, and blue components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255; // Extract the red component
  const g = (bigint >> 8) & 255; // Extract the green component
  const b = bigint & 255; // Extract the blue component

  return `${r}, ${g}, ${b}`; // Return as a string
}

export const getTextColorBasedOnBG = (color: string) => {
  // Convert color to RGB
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for light colors, white for dark colors
  return luminance > 0.5 ? "#000000" : "#ffffff";
};

const globalTheme = getSessionStore("globalTheme");
export const isDarkTheme = globalTheme === THEME.DARK;
