import { getObjectFromSessionStore, setSessionStore } from "app/utils/sessionStore";
import { NOTIFICATION_IDENTIFIERS } from "app/utils/types";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, deleteToken } from "firebase/messaging";

const app = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
});

const messaging = getMessaging(app);

export const getTokenFromFirebase = () =>
  getToken(messaging, {
    vapidKey: process.env.FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => currentToken)
    .catch(() => null);

export const deleteDeviceToken = () => {
  deleteToken(messaging)
    .then((token) => token)
    .catch(() => {});
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
      const email = getLookUpResponse?.email;

      if (email === payload?.data?.email) {
        const notificationBell = document.querySelector<HTMLSpanElement>(".notification-bell");

        if (notificationBell) {
          setSessionStore("showNotificationDot", true);
          notificationBell.classList.remove("hidden");
          if (payload?.data?.identifier === NOTIFICATION_IDENTIFIERS.PROPOSAL_RECEIVED) {
            setSessionStore("newProposalReceived", true);
            const proposalReceivedBadge = document.querySelector<HTMLSpanElement>(
              ".proposal-received-badge",
            );
            proposalReceivedBadge?.classList?.remove("hidden");
          }
        }
        resolve(payload);
      }
    });
  });
