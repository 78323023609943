import { getIsAgencyManagerActiveData } from "app/redux/features/isAgencyActive";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { TOASTTYPE, USERTYPE } from "app/utils/types";
import { Toast } from "primereact/toast";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Chat";
import { makeRequest } from "app/utils/makeRequest";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "app/utils/utils";
import { GET_CURR_LOGGED_IN_USER } from "app/redux/features/users/action";
import { getFetchUsers } from "app/redux/features/users";
import { isEmpty } from "ramda";

interface IRootChat {
  children: any;
}

const RootChat: FC<IRootChat> = ({ children }) => {
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const [users, setUsers] = useState([]);
  const [currUser, setCurrUser] = useState<any>(null);
  const userAccess = getObjectFromLocalStore("useraccess");
  const { isAgencyManagerActive } = useSelector(getIsAgencyManagerActiveData);
  const [loading, setLoading] = useState({
    currUser: true,
    users: true,
  });

  const { currLoggedInUser } = useSelector(getFetchUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    const { data, loading: currUserLoading, error } = currLoggedInUser;
    if (!currUserLoading && data) {
      setCurrUser({
        id: data?.id,
        firstName: (data?.firstName ?? data?.profile?.firstName) || "",
        lastName: (data?.lastName ?? data?.profile?.lastName) || "",
        email: data?.email,
        profilePictureLink: data?.profilePicture ?? data?.profile?.profilePicture,
      });
      setLoading((p) => ({ ...p, currUser: false }));
    } else if (!currUserLoading && error) {
      toast?.current?.show({
        severity: TOASTTYPE.ERROR,
        summary: t("agentBranding.label.error"),
        detail: error.message,
        life: 3000,
      });
      setLoading((p) => ({ ...p, currUser: false }));
    }
  }, [currLoggedInUser]);

  useEffect(() => {
    if ((isEmpty(currLoggedInUser.data) || !currLoggedInUser.data) && !currLoggedInUser.loading) {
      dispatch({
        type: GET_CURR_LOGGED_IN_USER,
      });
    }
  }, [currLoggedInUser]);

  useEffect(() => {
    makeRequest("chatUser")
      .get(
        userAccess.userType === USERTYPE.AGENT ? `isAgency=${isAgencyManagerActive}` : "",
        false,
        true,
      )
      .then((res) => {
        setUsers(
          res.data.data?.map((user: any) => ({
            id: user.id,
            firstName: user.profile?.firstName || "",
            lastName: user.profile?.lastName || "",
            email: user.email,
            profilePictureLink: user.profile?.profilePicture,
            title:
              userAccess.userType === USERTYPE.ADMIN && "isSuperAdmin" in user
                ? user.role?.roleName || (user.isSuperAdmin ? "Super Admin" : "Admin")
                : user.userType === USERTYPE.ADMIN
                ? "Case Manager"
                : user.isAgencyManager && user.parentAgencyId
                ? "Sub-Agency Manager"
                : user.isAgencyManager
                ? "Agency Manager"
                : user.isAssistant
                ? "Assistant"
                : user.userType === USERTYPE.AGENT
                ? "Advisor"
                : capitalizeFirstLetter(user.userType ?? ""),
          })),
        );
        setLoading((p) => ({ ...p, users: false }));
      })
      .catch((error) => {
        toast?.current?.show({
          severity: TOASTTYPE.ERROR,
          summary: t("agentBranding.label.error"),
          detail: error.message,
          life: 3000,
        });
        setLoading((p) => ({ ...p, users: false }));
      });
  }, [isAgencyManagerActive]);

  const { pathname } = useLocation();

  return pathname === "/chat" ? (
    children
  ) : loading.currUser || loading.users ? (
    children
  ) : (
    <Chat user={currUser} peers={users.filter((el: any) => el.id !== currUser?.id)}>
      {children}
    </Chat>
  );
};

export default RootChat;
