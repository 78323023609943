import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import useDarkTheme from "app/hooks/useDarkTheme";
import { getSessionStore, setSessionStore } from "app/utils/sessionStore";

import { TRIGGER_SAVE_THEME_SETTINGS } from "app/redux/features/agent-branding";
import { useDispatch } from "react-redux";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";

function DarkModeSelection() {
  const { branding = "" } = getSessionStore("lookUpResponse") ?? {};
  const { theme: appTheme, primaryColor = "" } = branding ?? {};
  const { isOpen, checked, setChecked } = useContext(ContactProfileDetailsContext);
  const [, setTheme]: [string, Dispatch<SetStateAction<string>>] = useDarkTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    setTheme(checked ? "dark" : "light");
    setSessionStore("globalTheme", checked ? "dark" : "light");
    const themeSelectionSlider = document.querySelector(".p-inputswitch-slider") as HTMLElement;
    const sliderColor = checked ? primaryColor || "#002446" : "#ced4da";
    themeSelectionSlider.style.backgroundColor = sliderColor;
  }, [checked]);

  const onThemeSelect = (value?: boolean) => {
    dispatch({
      type: TRIGGER_SAVE_THEME_SETTINGS,
      payload: {
        address: "saveThemeSettings",
        body: { theme: value ? "dark" : "light" },
      },
    });
  };

  useEffect(() => {
    appTheme === "dark" && setChecked(true);
  }, [appTheme]);

  return (
    <div className="full p-2 mt-2 relative group">
      <div
        className={`flex  gap-2 items-center justify-center ${isOpen ? "flex-row" : "flex-col"} `}
      >
        <i className={`pi pi-moon ${isOpen ? "" : "mb-2"}`} style={{ fontSize: "1rem" }} />

        <span
          className={`text-sm font-semibold ${
            isOpen ? "w-20" : "w-0 p-0 hidden"
          } transition-all overflow-hidden`}
        >
          Dark Mode
        </span>
        <InputSwitch
          tooltip="Dark Mode"
          tooltipOptions={{
            className: "tooltip-wrapper",
          }}
          checked={checked}
          onChange={(e: InputSwitchChangeEvent) => {
            setChecked(e.value ?? false);
            onThemeSelect(e.value as boolean);
          }}
        />
      </div>
    </div>
  );
}

export default DarkModeSelection;
