import { payload } from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchContactListLoading,
  fetchContactListSuccess,
  fetchContactListError,
  GET_CONTACT_LIST,
  fetchContactTransactionsLoading,
  fetchContactTransactionsSuccess,
  fetchContactTransactionsError,
  GET_CONTACT_TRANSACTIONS,
} from "app/redux/features/contacts";

function* getClients(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, queryParams, key, requestParams },
    } = actions;
    yield put(fetchContactListLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchContactListSuccess(response.data));
  } catch (error: any) {
    yield put(fetchContactListError(error));
  }
}

function* getContactTransactions(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, queryParams, key, requestParams },
    } = actions;
    yield put(fetchContactTransactionsLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchContactTransactionsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchContactTransactionsError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_CONTACT_LIST, getClients);
  yield takeLatest(GET_CONTACT_TRANSACTIONS, getContactTransactions);
}

export default function* contactsSaga() {
  yield all([watchPendingSagas()]);
}
