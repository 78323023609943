import { getObjectFromSessionStore, removeSessionStore } from "app/utils/sessionStore";
import { useNavigate } from "react-router-dom";

export function useNotificationClick() {
  const navigate = useNavigate();
  const onNotificationclick = () => {
    const notificationBell = document.querySelector<HTMLSpanElement>(".notification-bell");
    const lookUpResponse = getObjectFromSessionStore("lookUpResponse");

    if (notificationBell && lookUpResponse?.unreadNotificationCount === 0) {
      removeSessionStore("showNotificationDot");
      notificationBell.classList.add("hidden");
    }
    navigate("/notification");
  };

  return onNotificationclick;
}
