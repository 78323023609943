import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  userInviteDetails: (boolean | undefined)[];
}

const initialState: State = {
  userInviteDetails: [false],
};

export const userInviteDetails = createSlice({
  name: "userInviteDetails",
  initialState,
  reducers: {
    setUserDetailsOnInvite: (state, action: PayloadAction<number>) => {
      const updated = [...state.userInviteDetails];
      updated[action.payload] = true;
      state.userInviteDetails = updated;
    },
    setUserDetailsOnDelete: (state, action: PayloadAction<number>) => {
      const updated = [...state.userInviteDetails];
      updated[action.payload] = undefined;
      state.userInviteDetails = updated;
    },
    setUserDetailsOnAdd: (state) => {
      const updated = [...state.userInviteDetails];
      updated.push(false);
      state.userInviteDetails = updated;
    },
    resetUserInviteDetailsState: (state) => {
      state.userInviteDetails = initialState.userInviteDetails;
    },
    setMultipleInviteDetails: (state, action: PayloadAction<boolean[]>) => {
      state.userInviteDetails = [...action.payload];
    },
  },
});

export const {
  setUserDetailsOnInvite,
  setUserDetailsOnDelete,
  setUserDetailsOnAdd,
  resetUserInviteDetailsState,
  setMultipleInviteDetails,
} = userInviteDetails.actions;
export const userInviteDetailsState = (state: RootState) => state.userInviteDetailsState;

export const userInviteDetailsReducer = userInviteDetails.reducer;
