import {
  GET_ADMINS_LIST,
  GET_ROLES_DATA,
  POST_ROLES_DATA,
  PUT_ADD_MEMBER,
  getAdminListError,
  getAdminListStart,
  getAdminListSuccess,
  getRolesError,
  getRolesSuccess,
  postRolesError,
  postRolesStart,
  postRolesSuccess,
  putAddMemberError,
  putAddMemberSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* rolesFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getRolesSuccess(response.data));
  } catch (error: any) {
    yield put(getRolesError(error));
  }
}

function* rolesPostRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(postRolesStart());
    const response = yield call(() => makeRequest(path).post(body));
    yield put(postRolesSuccess(response.data));
  } catch (error: any) {
    yield put(postRolesError(error));
  }
}

function* rolesFetchAdminListRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getAdminListStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getAdminListSuccess(response.data));
  } catch (error: any) {
    yield put(getAdminListError(error));
  }
}

function* rolesPutRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).put(key, requestBody, requestParams));
    yield put(putAddMemberSuccess(response.data));
  } catch (error: any) {
    yield put(putAddMemberError(error));
  }
}

function* watchReviewSagas() {
  yield takeLatest(GET_ROLES_DATA, rolesFetchRequest);
  yield takeLatest(POST_ROLES_DATA, rolesPostRequest);
  yield takeLatest(GET_ADMINS_LIST, rolesFetchAdminListRequest);
  yield takeLatest(PUT_ADD_MEMBER, rolesPutRequest);
}

export default function* rolesSaga() {
  yield all([watchReviewSagas()]);
}
