import {
  loanPast30DaysStart,
  loanPast30DaysSuccess,
  loanPast30DaysError,
  loanPastDuesStart,
  loanPastDuesSuccess,
  loanPastDuesError,
  loanNext30DaysStart,
  loanNext30DaysSuccess,
  loanNext30DaysError,
  loanNext45DaysStart,
  loanNext45DaysSuccess,
  loanNext45DaysError,
  LOANS_PAST_DUES_30_DAYS,
  LOANS_PAST_DUES,
  LOANS_NEXT_45_DAYS,
  LOANS_NEXT_30_DAYS,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* loanPast30DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(loanPast30DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(loanPast30DaysSuccess(response.data));
  } catch (error: any) {
    yield put(loanPast30DaysError(error));
  }
}

function* loanPastDuesRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(loanPastDuesStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(loanPastDuesSuccess(response.data));
  } catch (error: any) {
    yield put(loanPastDuesError(error));
  }
}
function* loanNext30DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(loanNext30DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(loanNext30DaysSuccess(response.data));
  } catch (error: any) {
    yield put(loanNext30DaysError(error));
  }
}
function* loanNext45DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(loanNext45DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(loanNext45DaysSuccess(response.data));
  } catch (error: any) {
    yield put(loanNext45DaysError(error));
  }
}

function* watchUpcominLoansSagas() {
  yield takeLatest(LOANS_PAST_DUES_30_DAYS, loanPast30DaysRequest);
  yield takeLatest(LOANS_PAST_DUES, loanPastDuesRequest);
  yield takeLatest(LOANS_NEXT_30_DAYS, loanNext30DaysRequest);
  yield takeLatest(LOANS_NEXT_45_DAYS, loanNext45DaysRequest);
}

export default function* upcomingLoansSaga() {
  yield all([watchUpcominLoansSagas()]);
}
