import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_EMAIL_PROFILE = "GET_EMAIL_PROFILE";
export const POST_EMAIL_PROFILE = "POST_EMAIL_PROFILE";
export const POST_PROFILE_OTP = "POST_PROFILE_OTP";
export const GET_PROFILE = "GET_PROFILE";
export const PATCH_PROFILE = "PATCH_PROFILE";

interface getEmailProfileInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  fetchEmailStart?: getEmailProfileInterface;
  fetchEmailSuccess: getEmailProfileInterface;
  postProfileStart?: getEmailProfileInterface;
  fetchEmailFailure: getEmailProfileInterface;
  postProfileSuccess: getEmailProfileInterface;
  postProfileFailure: getEmailProfileInterface;
  postOTPStart?: getEmailProfileInterface;
  postOTPFailure: getEmailProfileInterface;
  postOTPSuccess: getEmailProfileInterface;
  resetProfileState: getEmailProfileInterface;
  fetchProfileStart: getEmailProfileInterface;
  fetchProfileFailure: getEmailProfileInterface;
  fetchProfileSuccess: getEmailProfileInterface;
}

const initialState: responseType = {
  fetchEmailSuccess: { data: {}, error: "", loading: false },
  fetchEmailFailure: { data: {}, error: "", loading: false },
  postProfileSuccess: { data: {}, error: "", loading: false },
  postProfileFailure: { data: {}, error: "", loading: false },
  postProfileStart: { data: {}, error: "", loading: false },
  postOTPStart: { data: {}, error: "", loading: false },
  postOTPFailure: { data: {}, error: "", loading: false },
  postOTPSuccess: { data: {}, error: "", loading: false },
  resetProfileState: { data: {}, error: "", loading: false },
  fetchProfileStart: { data: {}, error: {}, loading: false },
  fetchProfileFailure: { data: {}, error: {}, loading: false },
  fetchProfileSuccess: { data: {}, error: {}, loading: false },
};

export const getEmailProfileSlice = createSlice({
  name: "getEmailProfile",
  initialState,
  reducers: {
    fetchEmailStart: (state) => {
      state.fetchEmailStart = { data: {}, error: "", loading: true };
    },
    fetchEmailSuccess: (state, action: PayloadAction<any>) => {
      state.fetchEmailStart = { data: {}, error: "", loading: false };
      state.fetchEmailSuccess = { data: action.payload, error: "", loading: false };
    },
    fetchEmailFailure: (state, action: PayloadAction<any>) => {
      state.fetchEmailFailure = { data: {}, error: action.payload, loading: false };
    },

    postProfileStart: (state) => {
      state.postProfileStart = { data: {}, error: "", loading: true };
    },
    postProfileSuccess: (state, action: PayloadAction<any>) => {
      state.postProfileStart = { data: {}, error: "", loading: false };
      state.postProfileSuccess = { data: action.payload, error: "", loading: false };
    },
    postProfileFailure: (state, action: PayloadAction<any>) => {
      state.postProfileStart = { data: {}, error: "", loading: false };
      state.postProfileFailure = { data: {}, error: action.payload, loading: false };
    },
    postOTPStart: (state) => {
      state.postOTPStart = { data: {}, error: "", loading: false };
    },
    postOTPSuccess: (state, action: PayloadAction<any>) => {
      state.postOTPSuccess = { data: action.payload, error: "", loading: true };
    },
    postOTPFailure: (state, action: PayloadAction<any>) => {
      state.postOTPFailure = { data: {}, error: action.payload, loading: false };
    },
    resetOtpState: (state) => {
      state.postOTPFailure = { data: {}, error: "", loading: false };
      state.postOTPSuccess = { data: {}, error: "", loading: false };
      state.postOTPStart = { data: {}, error: "", loading: false };
    },
    resetProfileResponseState: (state) => {
      state.postProfileStart = { data: {}, error: "", loading: false };
      state.postProfileSuccess = { data: {}, error: "", loading: false };
      state.postProfileFailure = { data: {}, error: "", loading: false };
    },
    fetchProfileStart: (state) => {
      state.fetchProfileStart = { data: {}, error: {}, loading: true };
    },
    fetchProfileSuccess: (state, action: PayloadAction<any>) => {
      state.fetchProfileSuccess = { data: action.payload, error: {}, loading: false };
    },
    fetchProfileFailure: (state, action: PayloadAction<any>) => {
      state.fetchProfileFailure = { data: {}, error: action.payload, loading: false };
    },
    resetProfileState: (state) => {
      state.fetchEmailSuccess = { data: {}, error: "", loading: false };
      state.fetchEmailFailure = { data: {}, error: "", loading: false };
      state.postProfileSuccess = { data: {}, error: "", loading: false };
      state.postProfileFailure = { data: {}, error: "", loading: false };
      state.postOTPStart = { data: {}, error: "", loading: false };
      state.postOTPFailure = { data: {}, error: "", loading: false };
      state.postOTPSuccess = { data: {}, error: "", loading: false };
      state.fetchProfileStart = { data: {}, error: {}, loading: false };
      state.fetchProfileSuccess = { data: {}, error: {}, loading: false };
      state.fetchProfileFailure = { data: {}, error: {}, loading: false };
    },
    resetProfileSuccess: (state) => {
      state.fetchEmailSuccess = { data: {}, error: "", loading: false };
      state.fetchEmailFailure = { data: {}, error: "", loading: false };
      state.postProfileSuccess = { data: {}, error: "", loading: false };
      state.postProfileFailure = { data: {}, error: "", loading: false };
      state.postOTPStart = { data: {}, error: "", loading: false };
      state.postOTPFailure = { data: {}, error: "", loading: false };
      state.postOTPSuccess = { data: {}, error: "", loading: false };
    },
    resetState: () => initialState,
  },
});

export const {
  fetchEmailStart,
  fetchEmailSuccess,
  fetchEmailFailure,
  postProfileStart,
  postProfileSuccess,
  postProfileFailure,
  postOTPStart,
  postOTPSuccess,
  postOTPFailure,
  resetProfileState,
  resetOtpState,
  fetchProfileFailure,
  fetchProfileSuccess,
  fetchProfileStart,
  resetProfileResponseState,
  resetProfileSuccess,
  resetState,
} = getEmailProfileSlice.actions;

export const getEmail = (state: RootState) => state.getEmailProfile;

export default getEmailProfileSlice.reducer;
