import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const LOANS_NEXT_30_DAYS = "LOANS_NEXT_30_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const loanNext30DaysSlice = createSlice({
  name: "loanNext30Days",
  initialState,
  reducers: {
    loanNext30DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    loanNext30DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    loanNext30DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetLoanNext30DaysState: () => initialState,
  },
});

export const {
  loanNext30DaysStart,
  loanNext30DaysSuccess,
  loanNext30DaysError,
  resetLoanNext30DaysState,
} = loanNext30DaysSlice.actions;

export const loanNext30Days = (state: RootState) => state.loanNext30Days;
export default loanNext30DaysSlice.reducer;
