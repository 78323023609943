import {
  deleteUploadDocumentStart,
  deleteUploadDocumentSuccess,
  deleteUploadDocumentError,
  DELETE_UPLOADED_DOCUMENT,
} from "app/redux";
import { DELETE_UPLOADED_FINAL_PROPOSAL_DOCUMENT } from "app/redux/features/fileUploadComponent/DeleteDocument";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* deleteUploadDocumentRequest(
  actions: FinancialInfoActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(deleteUploadDocumentStart());
    const response = yield call(() => makeRequest(path).delete(key, requestParams));
    yield put(deleteUploadDocumentSuccess(response.data));
  } catch (error: any) {
    yield put(deleteUploadDocumentError(error));
  }
}
export default function* deleteUploadDocumentSaga() {
  yield all([takeLatest(DELETE_UPLOADED_DOCUMENT, deleteUploadDocumentRequest)]);
  yield all([takeLatest(DELETE_UPLOADED_FINAL_PROPOSAL_DOCUMENT, deleteUploadDocumentRequest)]);
}
