import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";
export const CONVERT_TO_AGENT = "CONVERT_TO_AGENT";
export const UPDATE_SESSION_COUNT = "UPDATE_SESSION_COUNT";

const initialState = {
  loading: false,
  data: {} as any,
  error: {},
  loadingAgentConvert: false,
  dataAgentConvert: {} as any,
  errorAgentConvert: {} as any,
  lookUpSessionUpdateCount: 0,
};

export const agentProfileSlice = createSlice({
  name: "agentProfile",
  initialState,
  reducers: {
    getAgentProfileStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    getAgentProfileSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getAgentProfileError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    convertAgentStart: (state) => {
      state.dataAgentConvert = {};
      state.errorAgentConvert = {};
      state.loadingAgentConvert = true;
    },
    convertAgentSuccess: (state, action: PayloadAction<any>) => {
      state.dataAgentConvert = action.payload;
      state.errorAgentConvert = {};
      state.loadingAgentConvert = false;
    },
    convertAgentError: (state, action: PayloadAction<any>) => {
      state.dataAgentConvert = {};
      state.errorAgentConvert = action.payload;
      state.loadingAgentConvert = false;
    },
    resetAgentConvertState: (state) => {
      state.dataAgentConvert = {};
      state.errorAgentConvert = {};
      state.loadingAgentConvert = false;
    },
    updateSessionUpdateCount: (state) => {
      state.lookUpSessionUpdateCount += 1;
    },
    resetGetAgentProfileState: () => initialState,
  },
});

export const {
  getAgentProfileStart,
  getAgentProfileSuccess,
  getAgentProfileError,
  convertAgentStart,
  convertAgentSuccess,
  convertAgentError,
  resetGetAgentProfileState,
  resetAgentConvertState,
  updateSessionUpdateCount,
} = agentProfileSlice.actions;

export const agentProfileState = (state: RootState) => state.agentProfile;
export default agentProfileSlice.reducer;
