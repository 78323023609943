import {
  DELETE_AGENT_ASSISTANT,
  GET_AGENCY_AGENT_COUNT,
  GET_AGENTS_LIST,
  GET_ALL_AGENT_LIST,
  agencyAgentsCountsError,
  agencyAgentsCountsStart,
  agencyAgentsCountsSuccess,
  agentAllListingError,
  agentAllListingStart,
  agentAllListingSuccess,
  agentListingError,
  agentListingStart,
  agentListingSuccess,
  agentRemoveError,
  agentRemoveStart,
  agentRemoveSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* agentsListingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(agentListingStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    yield put(agentListingSuccess(response.data));
  } catch (error: any) {
    yield put(agentListingError(error));
  }
}

function* agentsAllListingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(agentAllListingStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    yield put(agentAllListingSuccess(response.data));
  } catch (error: any) {
    yield put(agentAllListingError(error));
  }
}
function* agencyAgentsCountRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(agencyAgentsCountsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    yield put(agencyAgentsCountsSuccess(response.data));
  } catch (error: any) {
    yield put(agencyAgentsCountsError(error));
  }
}

function* deleteAgentsAssistant(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(agentRemoveStart());
    const response = yield call(() => makeRequest(path).deleteWithBody(body));
    yield put(agentRemoveSuccess(response.data));
  } catch (error: any) {
    yield put(agentRemoveError(error));
  }
}

function* watchAgentListsSagas() {
  yield takeLatest(GET_AGENTS_LIST, agentsListingRequest);
  yield takeLatest(GET_ALL_AGENT_LIST, agentsAllListingRequest);
  yield takeLatest(GET_AGENCY_AGENT_COUNT, agencyAgentsCountRequest);
  yield takeLatest(DELETE_AGENT_ASSISTANT, deleteAgentsAssistant);
}

export default function* agentListingSaga() {
  yield all([watchAgentListsSagas()]);
}
