import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_BLOCK_VERIFY_API_REQUEST = "TRIGGER_BLOCK_VERIFY_API_REQUEST";

interface BlockApiInterface {
  blockVerifyApiData: any;
  blockVerifyApiError: any;
}

const initialState: BlockApiInterface = {
  blockVerifyApiData: "",
  blockVerifyApiError: "",
};

export const blockTokenSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    blockVerifyToken: (state, action: PayloadAction<any[]>) => {
      state.blockVerifyApiData = action.payload;
    },
    blockVerifyTokenError: (state, action: PayloadAction<string>) => {
      state.blockVerifyApiError = action.payload;
    },
    resetBlockVerifyTokenState: (state) => {
      state.blockVerifyApiData = "";
      state.blockVerifyApiError = "";
    },
  },
});

export const { blockVerifyToken, blockVerifyTokenError, resetBlockVerifyTokenState } =
  blockTokenSlice.actions;
export const getBlockVerifyState = (state: RootState) => state.blockVerifyToken;

export default blockTokenSlice.reducer;
