import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_CONTACT_SUMMARY = "TRIGGER_CONTACT_SUMMARY";

interface DataFormat {
  data?: any;
  loading?: boolean;
  error?: string | null | object | any;
}

interface ResponseType {
  fetchContactSummaryStart: DataFormat;
  fetchContactSummarySuccess: DataFormat;
  fetchContactSummaryFailure: DataFormat;
}

const initialState: ResponseType = {
  fetchContactSummaryStart: { data: {}, error: {}, loading: false },
  fetchContactSummarySuccess: { data: {}, error: {}, loading: false },
  fetchContactSummaryFailure: { data: {}, error: {}, loading: false },
};

export const conatctSummaryListSlice = createSlice({
  name: "contactSummary",
  initialState,
  reducers: {
    fetchContactSummaryStart: (state) => {
      state.fetchContactSummaryStart = { data: {}, error: {}, loading: true };
    },
    fetchContactSummarySuccess: (state, action: PayloadAction<any>) => {
      state.fetchContactSummarySuccess = { data: action.payload, error: {}, loading: false };
    },
    fetchContactSummaryFailure: (state, action: PayloadAction<any>) => {
      state.fetchContactSummaryFailure = { data: {}, error: action.payload, loading: false };
    },

    resetContactSummaryCardData: (state) => {
      state.fetchContactSummaryStart = { data: {}, error: {}, loading: false };
      state.fetchContactSummarySuccess = { data: {}, error: {}, loading: false };
      state.fetchContactSummaryFailure = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchContactSummaryStart,
  fetchContactSummarySuccess,
  fetchContactSummaryFailure,
  resetContactSummaryCardData,
} = conatctSummaryListSlice.actions;

export const getContactSummary = (state: RootState) => state.conatctSummary;

export default conatctSummaryListSlice.reducer;
