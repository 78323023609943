import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_BRANDING_LIST = "TRIGGER_GET_BRANDING_LIST";
export const TRIGGER_GET_CASE_MANAGER = "TRIGGER_GET_CASE_MANAGER";
export const TRIGGER_INVITE_AGENT = "TRIGGER_INVITE_AGENT";

interface State {
  data: any;
  error: any;
  loading: boolean;
  brandingData: any;
  brandingError: any;
  brandingLoading: boolean;
  caseManagerData: any;
  caseManagerError: any;
  caseManagerLoading: boolean;
  agentInviteData: any;
  agentInviteError: any;
  agentInviteLoading: boolean;
}

const initialState: State = {
  data: {},
  error: {},
  loading: false,
  brandingData: {},
  brandingError: {},
  brandingLoading: false,
  caseManagerData: {},
  caseManagerError: {},
  caseManagerLoading: false,
  agentInviteData: {},
  agentInviteError: {},
  agentInviteLoading: false,
};

export const inviteAgency = createSlice({
  name: "inviteAgency",
  initialState,
  reducers: {
    setInviteAgencySuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = {};
    },
    setInviteAgencyFailure: (state, action: PayloadAction<string>) => {
      state.data = {};
      state.loading = false;
      state.error = action.payload;
    },
    setInviteAgencyOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    setBrandingSuccess: (state, action: PayloadAction<any[]>) => {
      state.brandingData = action.payload;
      state.brandingLoading = false;
      state.brandingError = {};
    },
    setBrandingFailure: (state, action: PayloadAction<string>) => {
      state.brandingData = {};
      state.brandingLoading = false;
      state.brandingError = action.payload;
    },
    setBrandingOngoing: (state) => {
      state.brandingData = {};
      state.brandingLoading = true;
      state.brandingError = {};
    },
    setCaseManagerSuccess: (state, action: PayloadAction<any[]>) => {
      state.caseManagerData = action.payload;
      state.caseManagerLoading = false;
      state.caseManagerError = {};
    },
    setCaseManagerFailure: (state, action: PayloadAction<string>) => {
      state.caseManagerData = {};
      state.caseManagerLoading = false;
      state.caseManagerError = action.payload;
    },
    setCaseManagerOngoing: (state) => {
      state.caseManagerData = {};
      state.caseManagerLoading = true;
      state.caseManagerError = {};
    },
    setInviteAgentSuccess: (state, action: PayloadAction<any[]>) => {
      state.agentInviteData = action.payload;
      state.agentInviteLoading = false;
      state.agentInviteError = {};
    },
    setInviteAgentFailure: (state, action: PayloadAction<string>) => {
      state.agentInviteData = {};
      state.agentInviteLoading = false;
      state.agentInviteError = action.payload;
    },
    setInviteAgentOngoing: (state) => {
      state.agentInviteData = {};
      state.agentInviteLoading = true;
      state.agentInviteError = {};
    },
    resetState: () => initialState,
  },
});

export const {
  setInviteAgencySuccess,
  setInviteAgencyFailure,
  setInviteAgencyOngoing,
  setBrandingSuccess,
  setBrandingFailure,
  setBrandingOngoing,
  setCaseManagerSuccess,
  setCaseManagerFailure,
  setCaseManagerOngoing,
  setInviteAgentSuccess,
  setInviteAgentFailure,
  setInviteAgentOngoing,
  resetState,
} = inviteAgency.actions;
export const getInviteAgency = (state: RootState) => state.inviteAgency;

export const inviteAgencyReducer = inviteAgency.reducer;
