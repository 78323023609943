import {
  TRIGGER_LOGOUT_REQUEST,
  logoutSucess,
  logoutRequestError,
  setFCMToken,
  resetLogoutState,
  resetLoginState,
  resetAgentProfileLookup,
} from "app/redux";
import { logOutStart } from "app/redux/features/logout";
import { clearLocalStore } from "app/utils/localStore";
import { makeRequest } from "app/utils/makeRequest";
import { clearSessionStore } from "app/utils/sessionStore";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* logoutRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    yield put(logOutStart());
    const response = yield call(() => makeRequest(path).post());
    clearLocalStore();
    clearSessionStore();
    yield put(resetLogoutState());
    yield put(resetLoginState());
    yield put(logoutSucess(response.data));
    yield put(setFCMToken(false));
    yield put(resetAgentProfileLookup());
  } catch (error: any) {
    yield put(logoutRequestError(error));
  }
}

function* watchLogoutRequest() {
  yield takeLatest(TRIGGER_LOGOUT_REQUEST, logoutRequest);
}

export default function* logoutSaga() {
  yield all([watchLogoutRequest()]);
}
