import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_ROLES_DATA = "GET_ROLES_DATA";
export const POST_ROLES_DATA = "POST_ROLES_DATA";
export const GET_ADMINS_LIST = "GET_ADMINS_LIST";
export const PUT_ADD_MEMBER = "PUT_ADD_MEMBER";

interface rolesResponse {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  getRolesResponse?: rolesResponse;
  postRolesResponse?: rolesResponse;
  getAdminListResponse?: rolesResponse;
  putAddMemberResponse?: rolesResponse;
}

const initialState: responseType = {
  getRolesResponse: { data: {}, error: {}, loading: false },
  postRolesResponse: { data: {}, error: {}, loading: false },
  getAdminListResponse: { data: {}, error: {}, loading: false },
  putAddMemberResponse: { data: {}, error: {}, loading: false },
};

export const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getRolesStart: (state) => {
      state.getRolesResponse = { data: {}, error: {}, loading: true };
    },
    getRolesSuccess: (state, action: PayloadAction<responseType>) => {
      state.getRolesResponse = { data: action.payload, error: {}, loading: false };
    },
    getRolesError: (state, action: PayloadAction<responseType>) => {
      state.getRolesResponse = { data: {}, error: action.payload, loading: false };
    },
    resetGetRoles: (state) => {
      state.getRolesResponse = { data: {}, error: {}, loading: false };
    },
    postRolesStart: (state) => {
      state.postRolesResponse = { data: {}, error: {}, loading: true };
    },
    postRolesSuccess: (state, action: PayloadAction<responseType>) => {
      state.postRolesResponse = { data: action.payload, error: {}, loading: false };
    },
    postRolesError: (state, action: PayloadAction<responseType>) => {
      state.postRolesResponse = { data: {}, error: action.payload, loading: false };
    },
    resetPostRoles: (state) => {
      state.postRolesResponse = { data: {}, error: {}, loading: false };
    },
    getAdminListStart: (state) => {
      state.getAdminListResponse = { data: {}, error: {}, loading: true };
    },
    getAdminListSuccess: (state, action: PayloadAction<responseType>) => {
      state.getAdminListResponse = { data: action.payload, error: {}, loading: false };
    },
    getAdminListError: (state, action: PayloadAction<responseType>) => {
      state.getAdminListResponse = { data: {}, error: action.payload, loading: false };
    },
    resetGetAdminList: (state) => {
      state.getAdminListResponse = { data: {}, error: {}, loading: false };
    },
    putAddMemberStart: (state) => {
      state.putAddMemberResponse = { data: {}, error: {}, loading: true };
    },
    putAddMemberSuccess: (state, action: PayloadAction<responseType>) => {
      state.putAddMemberResponse = { data: action.payload, error: {}, loading: false };
    },
    putAddMemberError: (state, action: PayloadAction<responseType>) => {
      state.putAddMemberResponse = { data: {}, error: action.payload, loading: false };
    },
    resetPutAddMember: (state) => {
      state.putAddMemberResponse = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  getRolesError,
  getRolesStart,
  getRolesSuccess,
  resetGetRoles,
  postRolesError,
  postRolesStart,
  postRolesSuccess,
  resetPostRoles,
  getAdminListError,
  getAdminListStart,
  getAdminListSuccess,
  putAddMemberError,
  putAddMemberStart,
  putAddMemberSuccess,
  resetGetAdminList,
  resetPutAddMember,
} = RolesSlice.actions;

export const RolesLedgerStore = (state: RootState) => state.rolesSaga;

export default RolesSlice.reducer;
