import {
  TRIGGER_PLAN_UPGRADE_REQUEST,
  fetchPlanDataFailure,
  fetchPlanDataSuccess,
  fetchPlanDataStart,
} from "../../redux";
import { PlansActionPayload } from "../../utils/types";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

function* upgradePlanRequest(actions: PlansActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(fetchPlanDataStart());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(fetchPlanDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlanDataFailure(error.message));
  }
}

function* watchPlanUpgradePlans() {
  yield takeLatest(TRIGGER_PLAN_UPGRADE_REQUEST, upgradePlanRequest);
}

export default function* plansUpgradeSaga() {
  yield all([watchPlanUpgradePlans()]);
}
