import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_SA_AGENCY_MANAGERS = "GET_SA_AGENCY_MANAGERS";
export const GET_SA_AGENCY_AGENTS = "GET_SA_AGENCY_AGENTS";
export const GET_SA_AGENCY_ASSISTANTS = "GET_SA_AGENCY_ASSISTANTS";
export const PATCH_SA_AGENT_ROLE = "PATCH_SA_AGENT_ROLE";
export const DELETE_SUB_AGENT_ROLE = "DELETE_SUB_AGENT_ROLE";

const initialState = {
  managersLoading: false,
  managersData: {} as any,
  managersError: {},
  agentsLoading: false,
  agentsData: {} as any,
  agentsError: {},
  assistantsLoading: false,
  assistantsData: {} as any,
  assistantsError: {},
  updateAgentsRoleLoading: false,
  updateAgentsRoleData: {} as any,
  updateAgentsRoleError: {} as any,
  removeSubAgentLoading: false,
  removeSubAgentData: {} as any,
  removeSubAgentError: {} as any,
};

const saAgencyViewRolesSlice = createSlice({
  name: "getPDFReport",
  initialState,
  reducers: {
    getManagersStart: (state) => {
      state.managersData = {};
      state.managersError = {};
      state.managersLoading = true;
    },
    getManagersSuccess: (state, action: PayloadAction<any>) => {
      state.managersData = action.payload;
      state.managersError = {};
      state.managersLoading = false;
    },
    getManagersError: (state, action: PayloadAction<any>) => {
      state.managersData = {};
      state.managersError = action.payload;
      state.managersLoading = false;
    },
    getAgentsStart: (state) => {
      state.agentsData = {};
      state.agentsError = {};
      state.agentsLoading = true;
    },
    getAgentsSuccess: (state, action: PayloadAction<any>) => {
      state.agentsData = action.payload;
      state.agentsError = {};
      state.agentsLoading = false;
    },
    getAgentsError: (state, action: PayloadAction<any>) => {
      state.agentsData = {};
      state.agentsError = action.payload;
      state.agentsLoading = false;
    },
    getAssistantsStart: (state) => {
      state.assistantsData = {};
      state.assistantsError = {};
      state.assistantsLoading = true;
    },
    getAssistantsSuccess: (state, action: PayloadAction<any>) => {
      state.assistantsData = action.payload;
      state.assistantsError = {};
      state.assistantsLoading = false;
    },
    getAssistantsError: (state, action: PayloadAction<any>) => {
      state.assistantsData = {};
      state.assistantsError = action.payload;
      state.assistantsLoading = false;
    },
    patchAgentsRoleStart: (state) => {
      state.updateAgentsRoleData = {};
      state.updateAgentsRoleError = {};
      state.updateAgentsRoleLoading = true;
    },
    patchAgentsRoleSuccess: (state, action: PayloadAction<any>) => {
      state.updateAgentsRoleData = action.payload;
      state.updateAgentsRoleError = {};
      state.updateAgentsRoleLoading = false;
    },
    patchAgentsRoleError: (state, action: PayloadAction<any>) => {
      state.updateAgentsRoleData = {};
      state.updateAgentsRoleError = action.payload;
      state.updateAgentsRoleLoading = false;
    },
    resetUpdateRoleState: (state) => {
      state.updateAgentsRoleData = {};
      state.updateAgentsRoleError = {};
      state.updateAgentsRoleLoading = false;
    },
    resetGetAgencyViewRoleState: () => initialState,
    subAgentRemoveStart: (state) => {
      state.removeSubAgentData = {};
      state.removeSubAgentError = {};
      state.removeSubAgentLoading = true;
    },
    subAgentRemoveSuccess: (state, action: PayloadAction<any>) => {
      state.removeSubAgentData = action.payload;
      state.removeSubAgentError = {};
      state.removeSubAgentLoading = false;
    },
    subAgentRemoveError: (state, action: PayloadAction<any>) => {
      state.removeSubAgentData = {};
      state.removeSubAgentError = action.payload;
      state.removeSubAgentLoading = false;
    },
    resetSubAgentRemoveState: (state) => {
      state.removeSubAgentData = {};
      state.removeSubAgentError = {};
      state.removeSubAgentLoading = false;
    },
  },
});

export const {
  getManagersStart,
  getManagersSuccess,
  getManagersError,
  getAgentsStart,
  getAgentsSuccess,
  getAgentsError,
  getAssistantsStart,
  getAssistantsSuccess,
  getAssistantsError,
  patchAgentsRoleStart,
  patchAgentsRoleSuccess,
  patchAgentsRoleError,
  resetUpdateRoleState,
  resetGetAgencyViewRoleState,
  subAgentRemoveError,
  subAgentRemoveStart,
  subAgentRemoveSuccess,
  resetSubAgentRemoveState,
} = saAgencyViewRolesSlice.actions;

export const saAgencyViewRolesState = (state: RootState) => state.saAgencyViewRoles;

export default saAgencyViewRolesSlice.reducer;
