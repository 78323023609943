import {
  TRIGGER_SIGNIN_REQUEST,
  TRIGGER_SIGNUP_REQUEST,
  setToken,
  setError,
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_PHONE,
  VERIFY_TOKEN_PHONE,
  VERIFY_TOKEN_EMAIL,
  toggleTokenSuccess,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  RESET_PASSWORD_LINK,
  UPDATE_PASSWORD_EMAIL,
  UPDATE_PASSWORD_PHONE,
  CREATE_NEW_PASSWORD_LINK,
  resetPasswordLinkSuccess,
  setOtpMetaData,
  toggleTokenVerificationSuccess,
  toggleUpdatePasswordSuccess,
  UPDATE_FCM_TOKEN,
} from "app/redux/features/resetPassword";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  errorSettingFCMToken,
  setFCMToken,
  setLoginState,
  startUpdatingFCMToken,
} from "app/redux/features/login";

function* signInRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { data, path } = payload;
    const response = yield call(makeRequest(path).post, data);
    yield put(setLoginState(data));
    yield put(setToken(response.data));
  } catch (error: any) {
    yield put(setError(error));
  }
}

function* signUpRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address, body },
    } = actions;
    const response = yield call(() => makeRequest(address).post(body));
    yield put(setToken(response.data));
  } catch (error: any) {
    yield put(setError(error));
  }
}

function* emailResetPasswordInstructions(
  cartdata: PayloadAction<{ email: string }>,
): Generator<any, void, any> {
  const request = makeRequest("resetEmail");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleTokenSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleTokenSuccess({ isSuccess: false, message: e.message }));
  }
}

function* phoneResetPasswordInstructions(
  cartdata: PayloadAction<{ phoneNumber: string }>,
): Generator<any, void, any> {
  const request = makeRequest("resetPhone");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleTokenSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleTokenSuccess({ isSuccess: false, message: e.message }));
  }
}

function* verifyEmailToken(cartdata: any): Generator<any, void, any> {
  const request = makeRequest("resetEmailToken");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleTokenVerificationSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleTokenVerificationSuccess({ isSuccess: false, message: e.message }));
  }
}

function* verifyPhoneToken(cartdata: any): Generator<any, void, any> {
  const request = makeRequest("resetPhoneToken");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleTokenVerificationSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleTokenVerificationSuccess({ isSuccess: false, message: e.message }));
  }
}

function* createNewPasswordSettings(cartdata: any): Generator<any, void, any> {
  const request = makeRequest("settingsChangePassword");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleUpdatePasswordSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleUpdatePasswordSuccess({ isSuccess: false, message: e.message }));
  }
}

function* updatePassordByEmail(cartdata: any): Generator<any, void, any> {
  const request = makeRequest("resetPasswordEmail");
  try {
    yield call(request.post, cartdata.payload);

    yield put(toggleUpdatePasswordSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleUpdatePasswordSuccess({ isSuccess: false, message: e.message }));
  }
}

function* updatePasswordByPhone(cartdata: any): Generator<any, void, any> {
  const request = makeRequest("resetPasswordPhone");
  try {
    yield call(request.post, cartdata.payload);
    yield put(toggleUpdatePasswordSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(toggleUpdatePasswordSuccess({ isSuccess: false, message: e.message }));
  }
}

function* resetPasswordLink(cartdata: any): Generator<any, void, any> {
  try {
    const response = yield call(() =>
      makeRequest("resetPasswordLink").post({}, cartdata.payload.id, true, false),
    );
    yield put(resetPasswordLinkSuccess(true));
    yield put(setOtpMetaData({ otpMode: "Email", email: response.data.email }));
    yield put(toggleTokenSuccess({ isSuccess: true }));
  } catch (e: any) {
    yield put(resetPasswordLinkSuccess(false));
    yield put(toggleTokenSuccess({ isSuccess: false }));
  }
}

function* updateFCMToken(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address, body },
    } = actions;

    yield put(startUpdatingFCMToken());
    yield call(() => makeRequest(address).put("", body));
    yield put(setFCMToken(true));
  } catch (error: any) {
    yield put(errorSettingFCMToken(error));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(TRIGGER_SIGNIN_REQUEST, signUpRequest),
    takeLatest(TRIGGER_SIGNUP_REQUEST, signInRequest),
    takeLatest(RESET_PASSWORD_EMAIL, emailResetPasswordInstructions),
    takeLatest(RESET_PASSWORD_PHONE, phoneResetPasswordInstructions),
    takeLatest(VERIFY_TOKEN_EMAIL, verifyEmailToken),
    takeLatest(VERIFY_TOKEN_PHONE, verifyPhoneToken),
    takeLatest(UPDATE_PASSWORD_EMAIL, updatePassordByEmail),
    takeLatest(UPDATE_PASSWORD_PHONE, updatePasswordByPhone),
    takeLatest(CREATE_NEW_PASSWORD_LINK, createNewPasswordSettings),
    takeLatest(RESET_PASSWORD_LINK, resetPasswordLink),
    takeLatest(UPDATE_FCM_TOKEN, updateFCMToken),
  ]);
}
