import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_ALL_LOANS_TABLE_DATA = "TRIGGER_ALL_LOANS_TABLE_DATA";

interface responseType {
  allLoansDatafetchOnGoing: boolean;
  allLoansDatafetchSuccess: any;
  allLoansDatafetchError: any;
}

const initialState: responseType = {
  allLoansDatafetchOnGoing: false,
  allLoansDatafetchSuccess: {},
  allLoansDatafetchError: null,
};

export const allLoansViewSlice = createSlice({
  name: "allLoansView",
  initialState,
  reducers: {
    fetchAllLoansStart: (state) => {
      state.allLoansDatafetchOnGoing = true;
      state.allLoansDatafetchSuccess = {};
      state.allLoansDatafetchError = null;
    },
    fetchAllLoansSuccess: (state, action: PayloadAction<responseType>) => {
      state.allLoansDatafetchOnGoing = false;
      state.allLoansDatafetchSuccess = action.payload;
      state.allLoansDatafetchError = null;
    },
    fetchAllLoansFailure: (state, action: PayloadAction<any>) => {
      state.allLoansDatafetchOnGoing = false;
      state.allLoansDatafetchSuccess = {};
      state.allLoansDatafetchError = action.payload;
    },
  },
});

export const { fetchAllLoansStart, fetchAllLoansSuccess, fetchAllLoansFailure } =
  allLoansViewSlice.actions;

export const getAllLoansViewData = (state: RootState) => state.allLoansViewData;

export default allLoansViewSlice.reducer;
