import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_TIMELINE = "GET_TIMELINE";
export const GET_PROPOSAL_PDF = "GET_PROPOSAL_PDF";
export const GET_PROPOSAL_HELLO_SIGN_PDF = "GET_PROPOSAL_HELLO_SIGN_PDF";
export const CHECK_PROPOSAL_HELLO_SIGN_PDF_STATUS = "CHECK_PROPOSAL_HELLO_SIGN_PDF_STATUS";
export const POST_REQUESTED_CHANGES = "POST_REQUESTED_CHANGES";
export const GET_REQUESTED_CHANGES = "GET_REQUESTED_CHANGES";
export const RESTORE_PROPOSAL = "RESTORE_PROPOSAL";

interface proposalReviewResponse {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  fetchPDFStart?: proposalReviewResponse;
  fetchPDFSuccess?: proposalReviewResponse;
  fetchPDFError?: proposalReviewResponse;
  fetchTimelineSuccess?: proposalReviewResponse;
  fetchTimelineError?: proposalReviewResponse;
  submitRequestedChangesSuccess?: proposalReviewResponse;
  submitRequestedChangesError?: proposalReviewResponse;
  fetchRequestedChangesSuccess?: proposalReviewResponse;
  fetchRequestedChangesError?: proposalReviewResponse;
  fetchHelloSignPDF?: proposalReviewResponse;
  fetchHelloSignPDFStatus?: proposalReviewResponse;
  restoreProposal?: proposalReviewResponse;
}

const initialState: responseType = {
  fetchPDFStart: { data: {}, error: {}, loading: true },
  fetchPDFSuccess: { data: {}, loading: false },
  fetchPDFError: { error: {}, loading: false },
  fetchTimelineSuccess: { data: {}, loading: false },
  fetchTimelineError: { error: {}, loading: false },
  submitRequestedChangesSuccess: { data: {}, loading: false },
  submitRequestedChangesError: { error: {}, loading: false },
  fetchRequestedChangesSuccess: { data: {}, loading: false },
  fetchRequestedChangesError: { error: {}, loading: false },
  fetchHelloSignPDF: { data: {}, error: {}, loading: false },
  fetchHelloSignPDFStatus: { data: {}, error: {}, loading: false },
  restoreProposal: { data: {}, error: {}, loading: false },
};

export const proposalReviewSlice = createSlice({
  name: "proposalReview",
  initialState,
  reducers: {
    pdtFetchStart: (state) => {
      state.fetchPDFStart = { data: {}, error: {}, loading: true };
    },
    fetchPDFSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchPDFSuccess = { data: action.payload, loading: false };
    },
    fetchPDFFailure: (state, action: PayloadAction<responseType>) => {
      state.fetchPDFError = { error: action.payload, loading: false };
    },
    resetfetchPDFSuccessState: (state) => {
      state.fetchPDFSuccess = { data: {}, loading: false };
    },
    fetchTimelineSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchTimelineSuccess = { data: action.payload, loading: false };
    },
    fetchTimelineError: (state, action: PayloadAction<responseType>) => {
      state.fetchTimelineError = { error: action.payload, loading: false };
    },
    submitRequestedChangesSuccess: (state, action: PayloadAction<responseType>) => {
      state.submitRequestedChangesSuccess = { data: action.payload, loading: false };
    },
    submitRequestedChangesError: (state, action: PayloadAction<responseType>) => {
      state.submitRequestedChangesError = { error: action.payload, loading: false };
    },
    fetchRequestedChangesSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchRequestedChangesSuccess = { data: action.payload, loading: false };
    },
    fetchRequestedChangesError: (state, action: PayloadAction<responseType>) => {
      state.fetchRequestedChangesError = { error: action.payload, loading: false };
    },
    resetToInitialState: (state) => {
      state.fetchPDFError = { data: {}, error: {}, loading: false };
      state.fetchTimelineSuccess = { data: {}, error: {}, loading: false };
      state.fetchTimelineError = { data: {}, error: {}, loading: false };
      state.submitRequestedChangesError = { data: {}, error: {}, loading: false };
      state.submitRequestedChangesSuccess = { data: {}, error: {}, loading: false };
      state.fetchRequestedChangesSuccess = { data: {}, error: {}, loading: false };
      state.fetchRequestedChangesError = { data: {}, error: {}, loading: false };
    },
    helloSignPDFFetchStart: (state) => {
      state.fetchHelloSignPDF = { data: {}, error: {}, loading: true };
    },
    helloSignPDFFetchSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchHelloSignPDF = { data: action.payload, error: {}, loading: false };
    },
    helloSignPDFFetchFailure: (state, action: PayloadAction<responseType>) => {
      state.fetchHelloSignPDF = { data: {}, error: action.payload, loading: false };
    },
    helloSignPDFStatusFetchStart: (state) => {
      state.fetchHelloSignPDFStatus = { data: {}, error: {}, loading: true };
    },
    helloSignPDFStatusFetchSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchHelloSignPDFStatus = { data: action.payload, error: {}, loading: false };
    },
    helloSignPDFStatusFetchFailure: (state, action: PayloadAction<responseType>) => {
      state.fetchHelloSignPDFStatus = { data: {}, error: action.payload, loading: false };
    },
    restoreProposalStart: (state) => {
      state.restoreProposal = { data: {}, error: {}, loading: true };
    },
    restoreProposalSuccess: (state, action: PayloadAction<responseType>) => {
      state.restoreProposal = { data: action.payload, error: {}, loading: false };
    },
    restoreProposalError: (state, action: PayloadAction<responseType>) => {
      state.restoreProposal = { data: {}, error: action.payload, loading: false };
    },
    resetRestoreProposal: (state) => {
      state.restoreProposal = { data: {}, error: {}, loading: false };
    },
    resetPdfState: (state) => {
      state.fetchPDFSuccess = { data: {}, error: {}, loading: false };
    },
    resetHelloSignPDF: (state) => {
      state.fetchHelloSignPDF = { data: {}, error: {}, loading: false };
      state.fetchHelloSignPDFStatus = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchPDFSuccess,
  fetchPDFFailure,
  pdtFetchStart,
  fetchTimelineSuccess,
  fetchTimelineError,
  submitRequestedChangesSuccess,
  submitRequestedChangesError,
  resetToInitialState,
  fetchRequestedChangesSuccess,
  fetchRequestedChangesError,
  resetPdfState,
  helloSignPDFFetchFailure,
  helloSignPDFFetchStart,
  helloSignPDFFetchSuccess,
  helloSignPDFStatusFetchFailure,
  helloSignPDFStatusFetchStart,
  helloSignPDFStatusFetchSuccess,
  restoreProposalStart,
  restoreProposalSuccess,
  restoreProposalError,
  resetHelloSignPDF,
  resetfetchPDFSuccessState,
  resetRestoreProposal,
} = proposalReviewSlice.actions;

export const proposalReviewStore = (state: RootState) => state.reviewProposalSaga;

export default proposalReviewSlice.reducer;
