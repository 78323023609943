import {
  agentProfileLookupFailure,
  agentProfileLookupStart,
  agentProfileLookupSuccess,
  AGENT_PROFILE_LOOKUP,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { setObjectInSessionStore } from "app/utils/sessionStore";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* agentProfileLookUp(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;

    yield put(agentProfileLookupStart());
    const response = yield call(() => makeRequest(path).get());
    if (response.statusCode >= 400) yield put(agentProfileLookupFailure(response));
    else {
      setObjectInSessionStore("agentlookUpResponse", response?.data);
      yield put(agentProfileLookupSuccess(response.data));
    }
  } catch (error: any) {
    yield put(agentProfileLookupFailure(error));
  }
}

function* watchAgentProfileLookup() {
  yield takeLatest(AGENT_PROFILE_LOOKUP, agentProfileLookUp);
}

export default function* watchAgentProfileLookupSaga() {
  yield all([watchAgentProfileLookup()]);
}
