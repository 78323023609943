import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GENERATE_LR_INVOICE = "GENERATE_LR_INVOICE";
export const DELETE_LR_INVOICE = "DELETE_LR_INVOICE";
interface LoanReviewInvoiceInterface {
  generateLoading: boolean;
  generateData: any;
  generateError: any;
  deleteLoading: boolean;
  deleteData: any;
  deleteError: any;
}

const initialState: LoanReviewInvoiceInterface = {
  generateLoading: false,
  generateData: {},
  generateError: {},
  deleteLoading: false,
  deleteData: {},
  deleteError: {},
};

export const LoanReviewInvoiceSlice = createSlice({
  name: "LoanReviewInvoice",
  initialState,
  reducers: {
    generateLRInvoiceStart: (state) => {
      state.generateData = {};
      state.generateError = {};
      state.generateLoading = true;
    },
    generateLRInvoiceSuccess: (state, action: PayloadAction<any>) => {
      state.generateData = action.payload;
      state.generateError = {};
      state.generateLoading = false;
    },
    generateLRInvoiceError: (state, action: PayloadAction<any>) => {
      state.generateData = {};
      state.generateError = action.payload;
      state.generateLoading = false;
    },
    deleteLRInvoiceStart: (state) => {
      state.deleteData = {};
      state.deleteError = {};
      state.deleteLoading = true;
    },
    deleteLRInvoiceSuccess: (state, action: PayloadAction<any>) => {
      state.deleteData = action.payload;
      state.deleteError = {};
      state.deleteLoading = false;
    },
    deleteLRInvoiceError: (state, action: PayloadAction<any>) => {
      state.deleteData = {};
      state.deleteError = action.payload;
      state.deleteLoading = false;
    },
    resetLRInvoiceState: () => initialState,
  },
});

export const {
  generateLRInvoiceStart,
  generateLRInvoiceSuccess,
  generateLRInvoiceError,
  deleteLRInvoiceStart,
  deleteLRInvoiceSuccess,
  deleteLRInvoiceError,
  resetLRInvoiceState,
} = LoanReviewInvoiceSlice.actions;

export const LoanReviewInvoiceStateData = (state: RootState) => state.loanReviewInvoiceState;

export default LoanReviewInvoiceSlice.reducer;
