import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { DynamicFieldData } from "./dynamic-control-types";
import {
  SetStateAction,
  Dispatch,
  ReactNode,
  CSSProperties,
  ReactElement,
  KeyboardEvent,
} from "react";
import {
  DataTableStateEvent,
  DataTableSelection,
  DataTableCellClassNameOptions,
  DataTableValue,
} from "primereact/datatable";
import { SidebarItem, Branding } from "../Data/types/Dashboard";
import { LoadableComponent } from "@loadable/component";
import { Location } from "react-router-dom";
import { ColumnEditorOptions } from "primereact/column";
import { IconType } from "primereact/utils";
import { ButtonProps } from "primereact/button";
import { MenuItem } from "primereact/menuitem";

export enum SEVERITY {
  SECONDARY = "secondary",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  HELP = "help",
}

export enum PLANDURATION {
  MONTH = "month",
  YEAR = "year",
}

export enum SortOrderType {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export enum BUTTONSIZE {
  SMALL = "small",
  LARGE = "large",
}

export enum DATEFORMAT {
  TYPE1 = "dd/MM/yyyy",
  TYPE2 = "yyyy-MM-dd",
  TYPE3 = "MM/dd/yyyy",
  TYPE4 = "yyyy-MM-dd HH:mm:ss.SSS",
}

export enum TOAST {
  DURATION = "3000",
  DURATION_2000 = "2000",
  DURATION_3000 = 3000,
}

export enum USERTYPE {
  AGENT = "agent",
  ADMIN = "admin",
  CONTACT = "contact",
}

export enum COUNTDOWNINTERVAL {
  COUNTDOWN = 60,
  COUNTDOWNDURATION = 1000,
}

export enum PLANDETAILS {
  INTEREST_PAYMENT = "Will employee make interest payments to Employer?",
  LOAN_RATE = "What loan interest rate will be used?",
  REPAID = "When does Employer want to get repaid?",
  INCOME = "When does client want to see income?",
}
export enum BRANDINGCOLORS {
  DEFAULT = "#002446,#0283E8",
  PURPLE = "#5457CD,#DAB6FC",
  PINK = "#EC4899,#F38EC0",
  CYAN = "#06B6D4,#65D2E4",
  YELLOW = "#EAB308,#F2D066",
  GREEN = "#22C55E,#A0E6BA",
  CUSTOM = "Custom",
}

export enum THEME {
  LIGHT = "light",
  DARK = "dark",
}

export enum FONTSIZE {
  REGULAR = "regular",
  LARGE = "large",
  EXTRA_LARGE = "extraLarge",
}

export enum PLAN {
  STARTER = "starter",
  PREMIUM = "premium",
  ELITE = "elite",
}

export enum TOASTTYPE {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARN = "warn",
}

export enum LOGOTYPE {
  PRIMARYLOGO = "primaryLogo",
  SECONDARYLOGO = "secondaryLogo",
}

export enum ROLEACCESS {
  CREATE_EDIT_PROPOSAL = "create_edit_proposal",
  APPROVE_PROPOSAL = "approve_proposal",
  MEMBER_DETAILS = "member_details",
  INVITE_TEAM_MEMBERS = "invite_team_members",
  MEMBER_ROLES = "member_roles",
  REMOVE_TEAM_MEMBERS = "remove_team_members",
  CREATE_NEW_ROLES = "create_new_roles",
  INVITE_AGENT_AGENCY = "invite_agent_agency",
  INVITE_LENDERS = "invite_lenders",
  ASSIGN_ADMIN_TO_SUBSCRIBERS = "assign_admin_to_subscribers",
}

export enum INVESTMENT {
  TYPE = "investmentType",
  AMOUNT = "investmentAmount",
}

export enum CUSTOMTYPE {
  PRIMARYCOLOR = "primaryColor",
  SECONDARYCOLOR = "secondaryColor",
}

export enum POLICY_EXPIRATION_YEARS {
  "TO_AGE_62" = "TO_AGE_62",
  "TO_AGE_65" = "TO_AGE_65",
  "TO_AGE_67" = "TO_AGE_67",
  "TO_AGE_70" = "TO_AGE_70",
  "CUSTOM" = "Custom",
}

export interface Example {
  label: string;
}

export interface FormProps {
  fields?: DynamicFieldData[];
  title?: string | null;
  subtitle?: string | null;
  buttonLabel?: string | null;
  checkBox?: ReactElement;
  button?: ReactElement;
  passwordCheckList?: boolean;
  link?: ReactElement;
  onSubmit: SubmitHandler<FieldValues> | any;
  loading?: boolean;
  formMethods: UseFormReturn<FieldValues, any>;
  icon?: ReactElement;
  modalFooterContent?: ReactElement;
  modalOpen?: boolean;
  serverErrorMessage?: string | [] | null;
  showStepper?: boolean;
  handleError?: (v: unknown) => void;
  stepperImgSrc?: string | null;
  onSkip?: ((v: unknown) => void) | null;
  passwordType?: "create" | "forgot";
}

export interface EditFormProps {
  key: number | string;
  onSubmit: SubmitHandler<FieldValues> | any;
  fields: any;
  formMethods: UseFormReturn<FieldValues, any>;
  title?: string;
  subTitle?: string;
  handleUpload?: any;
  isDisabled?: boolean;
  handleClick?: () => void;
  handleDelete?: any;
  isViewMode?: boolean;
  setView?: Dispatch<SetStateAction<boolean>>;
  isCancelBtnDisabled?: boolean;
}

export interface TemplateWithoutForm {
  title?: string | null;
  subtitle?: string | null;
  buttonLabel?: string | null;
  serverErrorMessage?: string;
  handleError?: (v: unknown) => void;
}

export interface AfterPaymentProps {
  content: AfterPaymentContent;
  redirectUrl: string;
}

export interface AfterPaymentContent {
  item: { [key: string]: string };
  icon: string;
  buttonLabel: string;
}

export type ListItem = {
  text: string;
  access: boolean;
};

export type ListViewData = {
  title: string;
  listItems: ListItem[];
};

export interface CardComponentProps {
  header?: ReactNode;
  cardBody?: ReactNode;
  styles?: string;
}

export interface CardContentProps {
  fields: CardFieldsInterface[];
  style?: string;
  height?: string;
  profilePic?: string;
}

export interface CsvData {
  [key: string]: string;
}

export interface CardHeaderProps {
  header: CardHeaderInterface;
  subHeader?: string;
  haveDivider?: boolean;
}
export interface CardHeaderInterface {
  // [key: string]: string | undefined;
  icon?: string;
  svg?: any;
  svgStyle?: {
    container: {
      style: string;
    };
    svg: {
      style: string;
    };
  };
  label: string;
  isLabelUrl?: boolean;
  labelNavigatesTo?: string;
  value?: string;
  style?: string;
  propType?: any;
  propStyle?: any;
  tooltip?: any;
  tooltipContent?: any;
  nodeData?: any;
  lastLoginAt?: any;
}

export interface CardFieldsInterface {
  label: string;
  value: number | string | any;
  id: number | string;
  className?: string;
  title?: string;
}
export interface OtpModalInterface {
  visible: boolean;
  footerContent: any;
  closeIcon?: Dispatch<SetStateAction<boolean>>;
  title: string | null;
  subTitle: string | null;
  fields?: DynamicFieldData[] | any;
  serverErrorMessage?: string | null | [];
  formMethods: UseFormReturn<FieldValues, any>;
  isInviteAssistant?: boolean;
  width?: string;
}

export interface HeaderInterface {
  id?: string;
  icon?: string;
  name?: string;
  url?: string;
  label?: string;
  isActive?: boolean;
  disable?: boolean;
  content?: ReactNode;
  className?: string;
  desc?: ReactNode;
}

export interface DataTabInterface {
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  headers?: HeaderInterface[];
  styles?: CSSProperties | string;
  canNavigate?: boolean;
  commisionTab?: boolean;
  isBrandingDemo?: boolean;
}

export interface DataTableDataInterface {
  pagination?: number;
  rowsPerPage?: number[];
  styles?: CSSProperties;
  scrollable?: boolean;
  scrollHeight?: string;
  selectionMode?: "multiple" | "single";
  onRowClickNavigateTo?: string;
  column?: ColumnJson[];
  insuranceCompanyNames?: string[];
}

export interface ColumnJson {
  id?: string;
  body?: BodyInterface;
  field?: string;
  key?: string;
  label?: string;
  frozen?: boolean;
  header?: boolean;
  filters?: FilterInterface;
  style?: CSSProperties;
  align?: "center" | "left" | "right" | null;
  alignHeader?: "center" | "left" | "right" | null;
  actionItems?: ActionItemsInterface[];
  editColumn?: boolean;
  bodyStyle?: any;
  defaultValue?: string;
  editorType?: string;
  headerStyle?: any;
  editorMode?: {
    type?: string;
    dropDownOptions?: string[] | any;
    validation?: {
      limit?: number;
      range?: number;
    };
  };
  rowExpander?: boolean;
  rowEditable?: boolean;
  actionType?: string;
  classNames?: string;
}

export interface ActionItemsInterface {
  label?: string;
  icon?: string;
  command?: () => void;
  className?: string;
}

export interface NoProposalDataProps {
  label: string;
  subLabel: string;
  btnLabel?: string;
  type?: string;
  iconPos?: "left" | "right" | "top" | "bottom";
  icon?: IconType<ButtonProps>;
  onClick?: () => void;
  bigIcon?: any;
  mb?: string;
  mt?: string;
  isDisable?: boolean;
}

export interface BodyInterface {
  type?: string;
  profilePicture?: boolean;
  chip?: {
    chipIcon?: string;
    style?: CSSProperties;
    image?: string;
    chipLabel?: string;
  };
  button?: {
    label: string;
    style?: CSSProperties;
    action?: any;
    disabled?: boolean;
  };
  menu?: {
    items?: any;
  };
  tag?: {
    label?: string;
    style?: CSSProperties;
  };
  progressBar?: {
    widthOfSvg: number;
    widthOfArc: number;
  };
  commission?: {
    noLiquidityRider?: string;
    withLiquidityRider?: string;
    excess?: string;
    yearField?: string;
    noLiquidityRiderField?: string;
    withLiquidityRiderField?: string;
    excessField?: string;
    noLiquidityRiderInput?: any;
    withLiquidityRiderInput?: any;
    excessInput?: any;
  };
  afrJan?: string;
  afrJul?: string;
  provision?: {
    payout?: any;
    condition?: string;
    name?: string;
  };
  tooltip?: boolean;
  disabled?: boolean | undefined;
  pictureName?: {
    profilePicture: boolean;
    userNameField: string;
  };
}

interface FilterInterface {
  filterElement?: string;
  filterType?: string;
  filterMenu?: any;
  filterMethod?: any;
  value?: string | number | null | undefined;
  width?: string;
  maxLength?: number;
  placeholder?: string;
}

interface Country {
  name: string;
  code: string;
}

interface Representative {
  name: string;
  image: string;
}

export interface Customer {
  id?: number;
  name?: string;
  country?: Country;
  company?: string;
  date?: string;
  status: string;
  verified: boolean;
  activity: number;
  representative: Representative;
  balance: number;
  progress?: number;
}

export interface DataTableCommonInterface {
  tableHeaders?: DataTableDataInterface;
  tableData?: any;
  actionItems?: MenuItem[];
  selectedProducts?: Customer[] | DataTableSelection<any>;
  setSelectedProducts?: React.Dispatch<DataTableSelection<any>>;
  styles?: CSSProperties;
  lazyState?: DataTableStateEvent | any;
  setLazyState?: React.Dispatch<DataTableStateEvent>;
  isMenuColumn?: boolean;
  totalRecords: number;
  sortOrder?: null | 1 | -1 | 0;
  handleOnSort: (e: any) => void;
  handleOnPageChange: (e: any) => void;
  rows: number;
  tableConfig: { dataKey?: string; rowHover?: boolean };
  sortField?: string;
  loading?: boolean;
  first?: number;
  emptyMessage?: any;
  changePendingAgentEmail?: (email: string) => void;
  filterMenu?: any;
  setFilterMenu?: any;
  resetFilter?: any;
  filterDraftName?: any;
  setFilterDraftName?: any;
  filterSet?: any;
  utilizeID?: boolean;
  showPaginator?: boolean;
  columnGroup?: ReactNode;
  isFilterShow?: boolean;
  gridlines?: boolean;
  canEditCell?: (e: ColumnEditorOptions) => boolean;
  cellClassName?: (
    value: any,
    options: DataTableCellClassNameOptions<any>,
  ) => string | object | string;
  onCellEdit?: (val: any, e: ColumnEditorOptions) => void;
  formMethods?: object;
  customCellRenderer?: (data: any, options: ColumnJson) => ReactNode;
  inSort?: boolean;
  handleProposalDataCallback?: (haveSidebar: boolean, onClickRowData: any) => void;
  onRowClick?: (data: DataTableValue) => void;
  onKeyUpInputNumber?: (e: KeyboardEvent<HTMLSpanElement>, options: ColumnEditorOptions) => void;
  maxFractionDigits?: number;
  minFractionDigits?: number;
  rowClassName?: (data: any) => string | object;
  getSelectedId?: boolean;
}
export interface DashBoardInterface {
  fields?: SidebarItem[];
  branding?: Branding;
  tabHeaders?: HeaderInterface[];
  button?: boolean;
}
interface RequestProposalItem {
  label: string;
}

interface RequestProposalIcon {
  icon: string;
  url: string;
}

interface BreadCrumbJson {
  item: RequestProposalItem[];
  icon: RequestProposalIcon;
}
export interface DashBoardRightPanelInterface {
  tabHeaders?: HeaderInterface[];
  button?: boolean;
  cta?: boolean;
  title?: string;
  name?: string;
  breadCrumbJson?: BreadCrumbJson;
  isTableView?: boolean;
  rightContainerData?: any;
  componentWithSidebar?: boolean;
  onTabChange?: (tab: number) => void;
  isExportReportButton?: boolean;
  exportPdf?: () => void;
  exportCsv?: () => void;
}

export interface HeaderWithBadgeInterface {
  name: string;
  template?: boolean;
  marginVertical?: string;
  fontSize?: string;
  isBrandingDemo?: boolean;
}

interface PasswordReset {
  email: string;
  verifyToken: string;
  countryCode: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

export type Email = Pick<PasswordReset, "email">;
export type Phone = Pick<PasswordReset, "countryCode" & "phoneNumber">;
export type VerificationToken = Pick<PasswordReset, "verifyToken">;
export type VerifyEmail = Email & VerificationToken;
export type VerifyPhone = Phone & VerificationToken;

interface Label {
  label?: string;
}
export interface BreadCrumbInterface {
  item?: Label[];
  icon?: {
    icon: string;
    url: string;
  };
}

export interface ChipInfoInterface {
  id?: string;
  icon?: string;
  label?: any;
}

export interface CardBodyComponentInterface {
  header: CardHeaderInterface;
  fields: CardFieldsInterface[];
  styles: string;
  wrapperStyle?: string;
  height?: string;
  cardType: string;
  cardStyle?: string;
  data?: any;
}

export interface agentProfileDataInterface {
  id: string | null;
  email: string | null;
  userType: string | null;
  countryCode: string | number | null;
  phoneNumber: string | number | null;
  agency: {
    name: string | null;
  } | null;
  profile: {
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | Date | null;
    streetAddress: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zipCode: string | number | null;
    profilePicture: string | null;
    npn: string | number | null;
    designation: string | null;
  } | null;
  subscription: string | null;
}

export interface BrandingResponse {
  fontsize: string;
  primaryColor: string;
  primaryLogo: string;
  secondaryColor: string;
  secondaryLogo: string;
  subDomain: string;
  theme: string;
}

interface Activity {
  activityType?: string;
  activityDescription?: string;
  createdAt?: string;
}

export interface ContactProfileAPIDataInterface {
  data: {
    id?: string;
    plans?: any[];
    firstName?: string;
    lastName?: string;
    dateOfBirth: string;
    isInsured?: boolean;
    status?: boolean;
    state?: string;
    gender?: string;
    city?: string;
    contactType: string;
    email?: string;
    phoneVerificationDate?: string;
    phoneNumber?: string;
    profilePicture?: string;
    street?: string;
    country?: string;
    financial?: {
      yearlyIncome: string;
      netWorth: string;
    };
    health?: {
      height?: string;
      weight?: string;
      medicalCondition?: string;
      regularMedication?: string;
      NicotineInfo?: string;
    };
    activity?: Activity[] | [];
  };
}
export interface ContactProfileInterface {
  fields?: SidebarItem[];
  chipInfo?: ChipInfoInterface[];
  cardBodyData: CardBodyComponentInterface[];
  basicInfo: any;
  showProfile?: boolean;
}
export interface ProfileInterface {
  basicInfo: any;
}
export interface PlansListInterface {
  id: string;
  name: string;
  subtitle: string;
  title: string;
  description: string;
  logo: any;
  price: Array<Record<string, string | number>>;
}

export interface PlanPaymentResponse {
  checkout: string;
}

export interface PlanChangeRequestBody {
  priceId: string | number;
  isAgency?: boolean;
  isOnboarding?: boolean;
}

export interface PaymentUpdateStatusRequestBody {
  sessionId: string;
}

export interface PlansActionPayload {
  type: string;
  payload: {
    address: string;
    body?: PlanChangeRequestBody;
  };
}

export interface CommissionActionPayload {
  type: string;
  payload: {
    address: string;
    body?: CommissionBody[];
    key: string;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

export interface ProductBody {
  insuranceCompanyId: string;
  name: string;
  type: string;
  rowStart: number;
  mappings: [];
}

export interface InsuranceProductActionPayload {
  type: string;
  payload: {
    address: string;
    body?: ProductBody;
    key: string;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

export interface InsuranceCompanyActionPayload {
  type: string;
  payload: {
    address: string;
    body?: { name: string };
    key: string;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}
export interface CommissionBody {
  year: string;
  noLiquidityRider: string;
  withLiquidityRider: string;
  excess: string;
}

export interface FinancialInfoActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    body?: ContactFinance;
    requestParams: boolean;
    queryParams: boolean;
  };
}

export interface ContactFinance {
  employmentType: string;
  yearlyIncome: string;
  netWorth: string;
  companyName: string;
  positionTitle: string;
  companyAddress: string;
  investmentInfo?: { investmentType: string; investmentAmount: string };
}

export interface ContactHealth {
  height: "string";
  weight: "string";
  medicalCondition: "string";
  regularMedication: "string";
  nicotineInfo: "string";
}

export interface payload {
  type: string;
  payload: {
    address: string;
    key: string;
    body?: ContactFinance;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}
export interface ContactProfile {
  firstName: "string";
  lastName: "string";
  dob: "string";
  zipCode: "string";
  state: "string";
  country: "string";
  profilePicture: "string";
  street: "string";
}

export interface BasicFormProps {
  activeIndex: number;
  handleClick: (dt?: any) => void;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setProposalType?: Dispatch<SetStateAction<string>>;
  proposalType?: ProposalTypes | string;
}

export interface FormDataUI {
  planDetails: any;
  basicDetails: {
    proposalType: string;
    primaryContact: string | number;
    yearlyIncome: string | number;
    netWorth: string | number;
    isInsurerSamePerson: string;
  };
  insuredDetails: {
    insuredName: string;
    height: string | number;
    dateOfBirth: Date | string;
    weight: string | number;
    medicalConditions: string;
    regularMedication?: string;
    insuranceCompany: string;
    priorities: string;
    gender?: string;
    email?: string;
    nicotineOrTobaccoUsage: string;
  };
}

export interface BuySellCompleteDetailsUI {
  basicDetails: {
    proposalType: string;
    primaryContact: string | number;
    legalNameOfEntity: string;
    state: string;
    country: string;
    zipCode: string;
    "No of Shareholders": string;
  };
  shareholderDetails: any;
  planDetails: any;
}

export interface AutoLogoutProps {
  ComposedClass: LoadableComponent<ReactNode>;
  location: Location;
}

export interface AdminSplitDollarActionPayload {
  type: string;
  payload: {
    address: string;
    body: AdminSplitDollar;
  };
}

export interface AdminSplitDollar {
  proposalId: string;
  insuranceCompanyId?: string;
  insuranceProductId?: string;
}

export interface EditCellAdminSplitDollarActionPayload {
  type: string;
  payload: {
    value: any;
    rowIdx: number;
    key: string;
  };
}

export interface AgentCommissionInterface {
  insuranceCompanyName: string;
  insuranceCompanyId: string;
  product: AgentProductInterface[];
}

export interface AgentProductInterface {
  productId: string;
  productName: string;
  commission: any[];
}

export interface PendingProgressInterface {
  label: string;
  progress: number;
}

export interface CustomAccordionInterface {
  header: ReactNode;
  content?: ReactNode;
  className?: string;
  isPendingProposal?: boolean;
  activeIndex?: any;
  onTabOpen?: any;
}

export interface DocumentViewInterface {
  id: string;
  doctype: string;
  docPath: string;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  onClick?: (t: string) => void;
  createdAt: string;
  name?: string;
  isRejectDisable?: any;
  disableButton?: boolean;
  content?: string;
  userAccessBy?: any;
  approveRejectNotApplicable?: boolean;
  letAdminRemoveTheDoc?: boolean;
  onDelete?: (id: string) => void;
}

export interface PendingInterface {
  isReadOnly?: boolean;
}

export enum ApprovalStatus {
  REJECTED = "rejected",
  PENDING = "pending",
  APPROVED = "approved",
  SENT = "Sent",
  PAID = "Paid",
}
export interface FileViewInterface {
  id?: string;
  doctype: string;
  docPath: string;
  onDelete?: (id: string) => void;
  approvalStatus?: ApprovalStatus;
  rejectRemark?: string;
  onClick?: (t: string) => void;
  handleDownload?: () => void;
  uploadDate?: string;
  className?: string;
  docName?: string;
  disableBtn?: boolean;
  userAccessBy?: any;
  canDelete?: boolean;
  isIllustrationNotApplicable?: boolean;
  disableRemoveButton?: boolean;
  bodyClassName?: string;
}

export interface FilePreviewInterface {
  url: string;
  isHalfWidth?: boolean;
  onCancel: () => void;
  loading: boolean;
  formattedDateSubmitted?: string;
  docCategory?: string;
  setButtons?: ReactNode;
  content?: ReactNode;
}

export enum PLAN_FINALIZATION_STATUS {
  PENDING_SENT_TO_AGENT = "pending_sent_to_agent",
  PENDING_SENT_TO_CONTACT = "pending_sent_to_contact",
  PENDING_DOWNLOADED = "pending_downloaded",
  PENDING_UPLOADED = "pending_uploaded",
  PENDING_SIGNED = "pending_signed",
  PENDING_FEEDBACK = "pending_feedback",
  PENDING_UPDATED = "pending_updated",
  PENDING_VERIFIED = "pending_verified",
  PLAN_CREATED = "plan_created",
  PENDING_SIGN_REJECTED = "pending_sign_rejected",
  PENDING_DRAFTS = "pending_drafts",
  PENDING_CREATED = "pending_created",
}

export enum PLAN_ACTIVITY_TYPE_LOGS {
  PLAN_STARTED = "planStarted",
  PLAN_EFFECTIVE_DATE_UPDATED = "planEffectiveDateUpdated",
  PLAN_STATUS_UPDATED = "planStatusUpdated",
  PLAN_DURATION_UPDATED = "planDurationUpdated",
  PLAN_REVIEW_COMPLETED = "planReviewCompleted",
  PLAN_OVERDUE = "planOverdue",
  PLAN_ANNIVERSARY = "planAnniversary",
}

export enum POLICY_ACTIVITY_TYPE_LOGS {
  POLICY_STARTED = "policyStarted",
  POLICY_EFFECTIVE_DATE_UPDATED = "policyEffectiveDateUpdated",
  POLICY_REVIEW_COMPLETED = "policyReviewCompleted",
  POLICY_OVERDUE = "policyOverdue",
  POLICY_ANNIVERSARY = "policyAnniversary",
}

export const ACTIVITY_STATUS: any = {
  planStarted: "Plan Started",
  planEffectiveDateUpdated: "Plan Effective Date Updated",
  planStatusUpdated: "Plan Status Updated",
  planDurationUpdated: "Plan Duration Updated",
  planReviewCompleted: "Plan Review Completed",
  planOverdue: "Plan Overdue",
  planAnniversary: "Plan Anniversary",
  policyStarted: "Policy Started",
  policyEffectiveDateUpdated: "Policy Effective Date Updated",
  policyReviewCompleted: "Policy Review Completed",
  policyOverdue: "Policy Overdue",
  policyAnniversary: "Policy Anniversary",
};

export interface IUser {
  firstName: string;
  lastName: string;
}
export interface IUserName {
  profile: IUser;
}
export interface CommissionPendingApprovalInterface {
  id?: string;
  userId?: string;
  user: IUserName;
  userType: string;
  modifiedAt: string;
}

export const PRODUCT_MAPPINGS: any = {
  policyYear: "Policy Year",
  insuredAge: "Insured Age",
  plannedPremium: "Planned Premium",
  annualIncome: "Annual Income",
  accumulatedValue: "Accumulated Value",
  surrenderValue: "Surrender Value",
  netDeathBenefit: "Net Death Benefit",
};
export interface insuranceProductMapInterface {
  isChecked?: boolean;
  tableName: string;
  label: string;
  columnName: string;
}

export const CUSTOM = "Custom";

export enum ProposalTypes {
  BuySell = "Buy Sell",
  SplitDollar = "Split Dollar",
  PremiumFinance = "Premium Finance",
}

export const DefaultPlanTypes: { [key: string]: string } = {
  [ProposalTypes.SplitDollar]: "Type - 1 Loan Regime",
  [ProposalTypes.BuySell]: "Type 1 - Entity Purchase",
};

export enum BinaryAnswers {
  Yes = "Yes",
  No = "No",
}

export enum LifeInsuranceType {
  Permanent = "Permanent",
  Term = "Term",
  Permanent_And_Term = "Both",
}

export interface FormField {
  fieldName?: string;
  inputType?: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  config?: {
    required?: string;
    pattern?: {
      value?: RegExp;
      message?: string;
    };
    maxLength?: {
      value?: number;
      message?: string;
    };
  };
  styles?: string;
}

export enum ProposalStatus {
  Draft = "draft",
}

export enum BS_LIFE_POLICY_LEDGER_POLICY_TYPES {
  GUARANTEED_UNIVERSAL_LIFE = "Guaranteed Universal Life",
  INDEXED_UNIVERSAL_LIFE = "Indexed Universal Life",
  TERM = "Term",
  UNIVERSAL_LIFE = "Universal Life",
  WHOLE_LIFE = "Whole Life",
  BUY_OUT = "Buy Out",
  OTHER = "Other",
}

/* eslint-disable @typescript-eslint/no-shadow */
export enum NOTIFICATION_CATEGORY_TYPES {
  Contact = "CONTACT",
  Proposal = "PROPOSAL",
  Pending = "PENDING",
  Plans = "PLAN",
  "Advisor/Agency" = "AGENT",
  Others = "OTHER",
}

export enum NOTIFICATION_IDENTIFIERS {
  VIEW_PROPOSALS = "VIEW_PROPOSALS",
  LIST_PROPOSALS = "LIST_PROPOSALS",
  VIEW_PLANS = "VIEW_PLANS",
  LIST_PLANS = "LIST_PLANS",
  LIST_PENDING = "LIST_PENDING",
  PLAN_SUMMARY = "PLAN_SUMMARY",
  PROFILE_ORG = "PROFILE_ORG",
  AGENT_PROFILE_ORG = "AGENT_PROFILE_ORG",
  PROPOSAL_RECEIVED = "PROPOSAL_RECEIVED",
  PROPOSAL_REQUESTED = "PROPOSAL_REQUESTED",
  PROPOSAL_PENDING_REVIEW = "PROPOSAL_PENDING_REVIEW",
  PROPOSAL_PENDING_APPROVAL = "PROPOSAL_PENDING_APPROVAL",
  LIST_AGENT = "LIST_AGENT",
  AGENT_PROFILE_BILLING = "AGENT_PROFILE_BILLING",
}

export enum INVOICE_TYPE {
  EMPLOYEE = "employee",
  EMPLOYER = "employer",
}

export enum POLICY_BENEFITS_FILTERS {
  ALL = "all",
  MONTH_TO_DATE = "month_to_date",
  YEAR_TO_DATE = "year_to_date",
  LAST_3_YEARS = "last_3_years",
  CUSTOM_PERIOD = "custom_period",
}

export enum PLAN_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  COMPLETED = "completed",
}

export enum PENDING_SECTION_ATTENTIONS {
  BASIC_INFO = "Basic Info",
  INSURANCE_UNDERWRITING = "Insurance Underwriting",
  BUSINESS_VALUATION = "Business valuation",
  LIFE_INSURANCE_UNDERWRITING = "Life Insurance Underwriting",
  DISABILITY_INSURANCE_UNDERWRITING = "Disability Insurance Underwriting",
  PLAN_FINALIZATION = "Plan Finalization",
  LEGAL_PLAN_FINALIZATION = "Legal & Plan Finalization",
}

export enum PENDING_DOC_CATEGORY_ATTENTIONS {
  COVER_LETTER = "Cover Letter",
  DRIVING_LICENSE = "Driver License",
  BUSINESS_VALUATION_DOC = "Business Valuation Doc",
  SHAREHOLDER_AND_OWNERSHIP_DOC = "Shareholder And Ownership Doc",
  MEDICAL_RECORD = "Medical Record",
  REMIT_PAYMENT = "Remit Payment",
  ADDITIONAL_REQ_DOC = "Additional Req Document",
  INSURANCE_ILLUSTRATION = "Insurance Illustration",
  LIFE_INSURANCE = "Life Insurance",
  DISABILITY_INSURANCE = "Disability Insurance",
  BUY_SELL_AGREEMENT = "Buy Sell Agreement",
  NOTE_SPLIT_DOLLAR_AGREEMENT = "Note For Split Dollar Agreement",
  SPLIT_DOLLAR_AGREEMENT = "Split Dollar Agreement",
  STAY_BONUS_AGREEMENT = "Stay Bonus Agreement",
  COLLATERAL_ASSIGNMENT_FORM = "Collateral Assigment form",
  FINAL_PROPOSAL = "Final Proposal",
  PLAN_ORIGINATION_FEE = "Plan Origination Fee",
  CLIENT_INVOICE = "Client Invoice",
}
