import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const LOANS_NEXT_45_DAYS = "LOANS_NEXT_45_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const loanNext45DaysSlice = createSlice({
  name: "loanNext45Days",
  initialState,
  reducers: {
    loanNext45DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    loanNext45DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    loanNext45DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetLoanNext45DaysState: () => initialState,
  },
});

export const {
  loanNext45DaysStart,
  loanNext45DaysSuccess,
  loanNext45DaysError,
  resetLoanNext45DaysState,
} = loanNext45DaysSlice.actions;

export const loanNext45Days = (state: RootState) => state.loanNext45Days;
export default loanNext45DaysSlice.reducer;
