import {
  setFetchAgentsOngoing,
  setFetchAgentsSuccess,
  setFetchUsersFailure,
  setFetchUsersOngoing,
  setFetchUsersSuccess,
} from "app/redux";
import {
  setCurrLoggedInUserError,
  setCurrLoggedInUserLoading,
  setCurrLoggedInUserSuccess,
  setDelAgentError,
  setDelAgentOngoing,
  setDelAgentSuccess,
} from "app/redux/features/users";
import { GET_CURR_LOGGED_IN_USER, TRIGGER_DELETE_AGENTS } from "app/redux/features/users/action";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { makeRequest } from "app/utils/makeRequest";
import { USERTYPE } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* fetchAdmins(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setFetchUsersOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setFetchUsersSuccess(response.data));
  } catch (error: any) {
    yield put(setFetchUsersFailure(error));
  }
}

function* fetchAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setFetchAgentsOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setFetchAgentsSuccess(response.data));
  } catch (error: any) {
    yield put(setFetchUsersFailure(error));
  }
}

function* deleteAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setDelAgentOngoing());
    const response = yield call(() => makeRequest(path).delete(key, requestParams, queryParams));
    yield put(setDelAgentSuccess(response.data));
  } catch (error: any) {
    yield put(setDelAgentError(error));
  }
}

function* getCurrLoggedInUser(): Generator<any, void, any> {
  try {
    yield put(setCurrLoggedInUserLoading());
    const userAccess = getObjectFromLocalStore("useraccess");
    const response = yield call(() =>
      makeRequest(
        userAccess.userType === USERTYPE.ADMIN
          ? "superAdminProfile"
          : userAccess.userType === USERTYPE.AGENT
          ? "agentProfile"
          : "contact",
      ).get(userAccess.userType === USERTYPE.ADMIN ? "," : "", true),
    );
    yield put(
      setCurrLoggedInUserSuccess(
        userAccess.userType === USERTYPE.ADMIN ? response?.data?.data : response?.data,
      ),
    );
  } catch (error: any) {
    yield put(setCurrLoggedInUserError(error));
  }
}

function* watchFetchUsers() {
  yield takeLatest("TRIGGER_FETCH_ADMINS", fetchAdmins);
  yield takeLatest("TRIGGER_FETCH_AGENTS", fetchAgents);
  yield takeLatest(TRIGGER_DELETE_AGENTS, deleteAgents);
  yield takeLatest(GET_CURR_LOGGED_IN_USER, getCurrLoggedInUser);
}

export default function* fetchUsersSaga() {
  yield all([watchFetchUsers()]);
}
