import { getSessionStore, setSessionStore } from "app/utils/sessionStore";
import { isEmpty } from "ramda";
import { useState, useEffect, type SetStateAction, type Dispatch } from "react";

export default function useDarkTheme(): [string, Dispatch<SetStateAction<string>>] {
  const mode = getSessionStore("globalTheme");
  const [theme, setTheme] = useState<string>(mode ?? "dark");
  const colorTheme: string = theme === "dark" ? "light" : "dark";

  useEffect(() => {
    if (!isEmpty(theme)) {
      const root = window.document.documentElement;
      root.classList.remove(colorTheme);
      root.classList.add(theme);
      setSessionStore("globalTheme", theme);
    }
  }, [theme, colorTheme]);

  return [colorTheme, setTheme];
}
