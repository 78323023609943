import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  isNotOwnProfile: boolean;
  isAgent: boolean;
  isGoingToSAManagerProfile: boolean;
  isComingFromURL: string;
}

const initialState: State = {
  isNotOwnProfile: false,
  isAgent: false,
  isGoingToSAManagerProfile: false,
  isComingFromURL: "",
};

export const isUsersOwnProfile = createSlice({
  name: "isUsersOwnProfile",
  initialState,
  reducers: {
    setIsNotOwnProfile: (state, action: PayloadAction<boolean>) => {
      state.isNotOwnProfile = action.payload;
    },
    setIsAgent: (state, action: PayloadAction<boolean>) => {
      state.isAgent = action.payload;
    },
    setGoingToSAManagerProfile: (state, action: PayloadAction<boolean>) => {
      state.isGoingToSAManagerProfile = action.payload;
    },
    setIsComingFromURL: (state, action: PayloadAction<string>) => {
      state.isComingFromURL = action.payload;
    },
  },
});

export const { setIsNotOwnProfile, setIsAgent, setGoingToSAManagerProfile, setIsComingFromURL } =
  isUsersOwnProfile.actions;
export const isUsersOwnProfileState = (state: RootState) => state.isUsersOwnProfileState;

export const isUsersOwnProfileReducer = isUsersOwnProfile.reducer;
