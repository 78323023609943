import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_PLAN_ADMIN_SPLIT_DOLLAR_LIST_REQUEST =
  "TRIGGER_PLAN_ADMIN_SPLIT_DOLLAR_LIST_REQUEST";
export const TRIGGER_SAVE_PLAN_ADMIN_SPLIT_DOLLAR = "TRIGGER_SAVE_PLAN_ADMIN_SPLIT_DOLLAR";
export const TRIGGER_EDIT_PLAN_ADMIN_SPLIT_DOLLAR_CELL =
  "TRIGGER_EDIT_PLAN_ADMIN_SPLIT_DOLLAR_CELL";
export const RESET_SAVE_DATA_RESPONSE = "RESET_SAVE_DATA_RESPONSE";
export const COPY_ROW_DATA = "COPY_ROW_DATA";

interface PlanAdminSplitDollarInterface {
  data: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
  newSDDataArr: any[];
  saveDataResponse: Record<string, string | number | object> | null;
  rowEditMinIdx: number;
  copiedData: {
    [key: string]: string | number;
  } | null;
  saveDataError: any;
}

const initialState: PlanAdminSplitDollarInterface = {
  data: [],
  loading: false,
  error: null,
  newSDDataArr: [],
  saveDataResponse: null,
  rowEditMinIdx: -1,
  copiedData: null,
  saveDataError: null,
};

export const planAdminSplitDollarSlice = createSlice({
  name: "planAdminSplitDollar",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
      state.rowEditMinIdx = -1;
    },
    fetchDataFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetPlanAdminSplitDollarState: (state) => ({ ...initialState, copiedData: state.copiedData }),
    editRowData: (state, action: PayloadAction<any>) => {
      if (state.rowEditMinIdx === -1) {
        state.rowEditMinIdx = +action.payload.rowIdx;
      } else {
        state.rowEditMinIdx = Math.min(state.rowEditMinIdx, +action.payload.rowIdx);
      }

      state.data = {
        ...state.data,
        planAdminSDData: {
          ...state.data.planAdminSDData,
          rows: action.payload.updatedRows,
        },
      };

      const firstRow = action.payload.splitDollarRowArr[0];
      const index1 = state.newSDDataArr.findIndex(
        (d) => +d.insuredAge === +firstRow.insuredAge && +d.policyYear === +firstRow.policyYear,
      );
      let updatedSdData = [...state.newSDDataArr];
      if (index1 > -1) {
        for (let i = 0; i < action.payload.splitDollarRowArr.length; i++) {
          if (index1 + i < state.newSDDataArr.length) {
            updatedSdData[i + index1] = action.payload.splitDollarRowArr[i];
          }
        }
      } else {
        updatedSdData = [...action.payload.splitDollarRowArr];
      }
      state.newSDDataArr = updatedSdData;
    },
    saveData: (state, action: PayloadAction<any>) => {
      state.saveDataResponse = action.payload;
      state.rowEditMinIdx = -1;
      state.newSDDataArr = [];
    },
    resetSaveDataResponseState: (state) => {
      state.saveDataResponse = null;
    },
    updateCopiedData: (state, action: PayloadAction<any>) => {
      state.copiedData = action.payload;
    },
    errorSaveData: (state, action: PayloadAction<any>) => {
      state.saveDataError = action.payload;
    },
    resetSaveDataError: (state) => {
      state.saveDataError = null;
    },
  },
});

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
  resetPlanAdminSplitDollarState,
  editRowData,
  resetSaveDataResponseState,
  saveData,
  updateCopiedData,
  errorSaveData,
  resetSaveDataError,
} = planAdminSplitDollarSlice.actions;

export const getPlanAdminSplitDollarList = (state: RootState) => state.planAdminSplitDollarList;

export default planAdminSplitDollarSlice.reducer;
