import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_SWITCH_USER,
  postAuthSwitchFailure,
  postAuthSwitchStart,
  postAuthSwitchSuccess,
} from "app/redux";

function* postAuthSwitchRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(postAuthSwitchStart());
    const response = yield call(() =>
      makeRequest(address).post(body, key, requestParams, queryParams),
    );
    yield put(postAuthSwitchSuccess(response.data));
  } catch (error: any) {
    yield put(postAuthSwitchFailure(error));
  }
}

function* watchAuthSwitch() {
  yield takeLatest(TRIGGER_SWITCH_USER, postAuthSwitchRequest);
}

export default function* authSwitchSaga() {
  yield all([watchAuthSwitch()]);
}
