import {
  postInviteStart,
  postInviteSuccess,
  postInviteFailure,
  inviteGetRolesStart,
  inviteGetRolesSuccess,
  getRolesFailure,
  GET_ROLES,
  POST_INVITE_MEMBER,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* getRolesRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address } = payload;
    yield put(inviteGetRolesStart());
    const response = yield call(() => makeRequest(address).get());
    yield put(inviteGetRolesSuccess(response.data));
  } catch (error: any) {
    yield put(getRolesFailure(error));
  }
}

function* postInviteTeamMemberRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body } = payload;
    yield put(postInviteStart());
    const response = yield call(() => makeRequest(address).post(body));
    yield put(postInviteSuccess(response.data));
  } catch (error: any) {
    yield put(postInviteFailure(error));
  }
}

function* watchInviteTeamSaga() {
  yield takeLatest(GET_ROLES, getRolesRequest);
  yield takeLatest(POST_INVITE_MEMBER, postInviteTeamMemberRequest);
}

export default function* inviteTeamMemberSaga() {
  yield all([watchInviteTeamSaga()]);
}
