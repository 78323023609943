import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_AGENTS_LIST = "GET_AGENTS_LIST";
export const GET_ALL_AGENT_LIST = "GET_ALL_AGENT_LIST";
export const DELETE_AGENT_ASSISTANT = "DELETE_AGENT_ASSISTANT";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
  removeAgentLoading: false,
  removeAgentData: {} as any,
  removeAgentError: {} as any,
  getAgentListLoading: false,
  getAgentListData: {} as any,
  getAgentListError: {} as any,
};

export const agentListingSlice = createSlice({
  name: "agentListing",
  initialState,
  reducers: {
    agentListingStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    agentListingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    agentListingError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    agentAllListingStart: (state) => {
      state.getAgentListData = {};
      state.getAgentListError = {};
      state.getAgentListLoading = true;
    },
    agentAllListingSuccess: (state, action: PayloadAction<any>) => {
      state.getAgentListData = action.payload;
      state.getAgentListError = {};
      state.getAgentListLoading = false;
    },
    agentAllListingError: (state, action: PayloadAction<any>) => {
      state.getAgentListData = {};
      state.getAgentListError = action.payload;
      state.getAgentListLoading = false;
    },
    resetAgentListingState: () => initialState,
    agentRemoveStart: (state) => {
      state.removeAgentData = {};
      state.removeAgentError = {};
      state.removeAgentLoading = true;
    },
    agentRemoveSuccess: (state, action: PayloadAction<any>) => {
      state.removeAgentData = action.payload;
      state.removeAgentError = {};
      state.removeAgentLoading = false;
    },
    agentRemoveError: (state, action: PayloadAction<any>) => {
      state.removeAgentData = {};
      state.removeAgentError = action.payload;
      state.removeAgentLoading = false;
    },
    resetAgentRemoveState: (state) => {
      state.removeAgentData = {};
      state.removeAgentError = {};
      state.removeAgentLoading = false;
    },
  },
});

export const {
  agentListingStart,
  agentListingSuccess,
  agentListingError,
  resetAgentListingState,
  agentRemoveSuccess,
  agentRemoveError,
  agentRemoveStart,
  resetAgentRemoveState,
  agentAllListingError,
  agentAllListingStart,
  agentAllListingSuccess,
} = agentListingSlice.actions;

export const agentListing = (state: RootState) => state.agentListing;
export default agentListingSlice.reducer;
