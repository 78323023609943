import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const LOANS_PAST_DUES_30_DAYS = "LOANS_PAST_DUES_30_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const loanPast30DaysSlice = createSlice({
  name: "loanPast30Days",
  initialState,
  reducers: {
    loanPast30DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    loanPast30DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    loanPast30DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetLoanPast30DaysState: () => initialState,
  },
});

export const {
  loanPast30DaysStart,
  loanPast30DaysSuccess,
  loanPast30DaysError,
  resetLoanPast30DaysState,
} = loanPast30DaysSlice.actions;

export const loanPast30Days = (state: RootState) => state.loanPast30Days;
export default loanPast30DaysSlice.reducer;
