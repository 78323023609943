import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_ROLES = "GET_ROLES";
export const POST_INVITE_MEMBER = "POST_INVITE_MEMBER";

interface State {
  rolesData: any;
  rolesError: any;
  rolesLoading: boolean;
  postInviteData: any;
  postInviteError: any;
  postInviteLoading: boolean;
}
const initialState: State = {
  rolesLoading: false,
  rolesData: {},
  rolesError: {},
  postInviteLoading: false,
  postInviteData: {},
  postInviteError: {},
};
export const inviteTeamMember = createSlice({
  name: "inviteTeamMember",
  initialState,
  reducers: {
    postInviteStart: (state) => {
      state.postInviteData = {};
      state.postInviteLoading = true;
      state.postInviteError = {};
    },
    postInviteSuccess: (state, action: PayloadAction<any[]>) => {
      state.postInviteData = action.payload;
      state.postInviteLoading = false;
      state.postInviteError = {};
    },
    postInviteFailure: (state, action: PayloadAction<string>) => {
      state.postInviteData = {};
      state.postInviteLoading = false;
      state.postInviteError = action.payload;
    },

    resetPostInvite: (state) => {
      state.postInviteData = {};
      state.postInviteLoading = false;
      state.postInviteError = {};
    },
    getRolesStart: (state) => {
      state.rolesData = {};
      state.rolesLoading = true;
      state.rolesError = {};
    },

    getRolesSuccess: (state, action: PayloadAction<any[]>) => {
      state.rolesData = action.payload;
      state.rolesLoading = false;
      state.rolesError = {};
    },
    getRolesFailure: (state, action: PayloadAction<string>) => {
      state.rolesData = {};
      state.rolesLoading = false;
      state.rolesError = action.payload;
    },

    resetInviteTeamState: () => initialState,
  },
});
export const {
  postInviteStart,
  postInviteSuccess,
  postInviteFailure,
  getRolesStart,
  getRolesSuccess,
  getRolesFailure,
  resetPostInvite,

  resetInviteTeamState,
} = inviteTeamMember.actions;
export const inviteTeamMemberState = (state: RootState) => state.inviteTeamMember;
export default inviteTeamMember.reducer;
