import BlockPopup from "./BlockPopup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

function LogoutConfirmDialog({ popUpOpen, setPopUpOpen, logoutHandle }: any) {
  const { t } = useTranslation();
  const footerContent = (
    <div>
      <Button
        label={t("general.button.cancel") as string}
        onClick={() => setPopUpOpen(false)}
        className="p-button-text border-gray-500 text-gray-500"
      />
      <Button
        label={t("general.button.logout") as string}
        className="bg-red-500 border-red-500"
        // loading={loader}
        onClick={logoutHandle}
        autoFocus
      />
    </div>
  );

  const header = t("general.logoutheader");
  const content = t("general.logoutcontent");
  return (
    <>
      <BlockPopup
        visible={popUpOpen}
        footerContent={footerContent}
        header={header}
        content={content}
        className="!w-[50vw]"
      />
    </>
  );
}

export default LogoutConfirmDialog;
