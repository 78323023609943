export const ContactLeftPanelItems = [
  {
    id: 1,
    name: "Dashboard",
    icon: "pi-th-large",
    key: "/dashboard",
  },
  {
    id: 2,
    name: "Plans",
    icon: "pi-book",
    key: "/plans",
  },
  {
    id: 3,
    name: "Proposals",
    icon: "pi-file",
    key: "/proposal",
  },
  {
    id: 4,
    name: "Pending",
    icon: "pi-hourglass",
    key: "/pending",
  },
];
