import {
  TRIGGER_ALL_LOANS_TABLE_DATA,
  fetchAllLoansStart,
  fetchAllLoansSuccess,
  fetchAllLoansFailure,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

interface ActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

function* getAllLoansViewData(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams, queryParams },
    } = actions;
    yield put(fetchAllLoansStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams, queryParams));
    yield put(fetchAllLoansSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAllLoansFailure(error));
  }
}

function* watchFetchAllLoansView() {
  yield takeLatest(TRIGGER_ALL_LOANS_TABLE_DATA, getAllLoansViewData);
}

export default function* watchAllLoansViewSaga() {
  yield all([watchFetchAllLoansView()]);
}
