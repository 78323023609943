import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { clearSessionStore } from "app/utils/sessionStore";

export const TRIGGER_LOGOUT_REQUEST = "TRIGGER_LOGOUT_REQUEST";

interface SignInInterface {
  isLoggingOut: boolean;
  logoutSuccess: object;
  logoutRequestError: object;
}

const initialState: SignInInterface = {
  isLoggingOut: false,
  logoutSuccess: {},
  logoutRequestError: {},
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logOutStart: (state) => {
      state.isLoggingOut = true;
    },
    logoutSucess: (state, action: PayloadAction<object>) => {
      state.logoutSuccess = action.payload;
      state.isLoggingOut = false;
      clearSessionStore();
    },
    logoutRequestError: (state, action: PayloadAction<object>) => {
      state.logoutRequestError = action.payload;
      state.isLoggingOut = false;
    },
    resetLogoutState: (state) => {
      state.isLoggingOut = false;
      state.logoutSuccess = {};
      state.logoutRequestError = {};
    },
  },
});

export const { logoutSucess, logoutRequestError, resetLogoutState, logOutStart } =
  logoutSlice.actions;
export const logoutResponseState = (state: RootState) => state.logout;

export default logoutSlice.reducer;
