import {
  GET_TEAM_ASSISTANT_DATA,
  REMOVE_TEAM_ASSISTANT_DATA,
  getTeamAssistantError,
  getTeamAssistantStart,
  getTeamAssistantSuccess,
  removeTeamAssistantError,
  removeTeamAssistantStart,
  removeTeamAssistantSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* teamAssistantFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getTeamAssistantStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getTeamAssistantSuccess(response.data));
  } catch (error: any) {
    yield put(getTeamAssistantError(error));
  }
}

function* deleteTeamAssistant(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(removeTeamAssistantStart());
    const response = yield call(() => makeRequest(path).deleteWithBody(body));
    yield put(removeTeamAssistantSuccess(response.data));
  } catch (error: any) {
    yield put(removeTeamAssistantError(error));
  }
}

function* watchReviewSagas() {
  yield takeLatest(GET_TEAM_ASSISTANT_DATA, teamAssistantFetchRequest);
  yield takeLatest(REMOVE_TEAM_ASSISTANT_DATA, deleteTeamAssistant);
}
export default function* teamAssistantSaga() {
  yield all([watchReviewSagas()]);
}
