import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_SWITCH_USER = "TRIGGER_SWITCH_USER";

interface CardData {
  data?: any;
  loading?: boolean;
  error?: string | null | object | any;
}

interface ResponseType {
  postAuthSwitchSuccess: CardData;
}

const initialState: ResponseType = {
  postAuthSwitchSuccess: { data: {}, error: {}, loading: false },
};

export const authSwitchSlice = createSlice({
  name: "authSwitch",
  initialState,
  reducers: {
    postAuthSwitchStart: (state) => {
      state.postAuthSwitchSuccess = { data: {}, error: {}, loading: true };
    },
    postAuthSwitchSuccess: (state, action: PayloadAction<any>) => {
      state.postAuthSwitchSuccess = { data: action.payload, error: {}, loading: false };
    },
    postAuthSwitchFailure: (state, action: PayloadAction<any>) => {
      state.postAuthSwitchSuccess = { data: {}, error: action.payload, loading: false };
    },
    resetAuthSwitchData: (state) => {
      state.postAuthSwitchSuccess = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  postAuthSwitchFailure,
  postAuthSwitchStart,
  postAuthSwitchSuccess,
  resetAuthSwitchData,
} = authSwitchSlice.actions;

export const getAuthSwitch = (state: RootState) => state.authSwitchSaga;

export default authSwitchSlice.reducer;
