import { payload } from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchOrgChartLoading,
  fetchOrgChartSuccess,
  fetchOrgChartError,
  GET_ORG_CHART,
} from "app/redux";

function* getOrgChartList(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(fetchOrgChartLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchOrgChartSuccess(response));
  } catch (error: any) {
    yield put(fetchOrgChartError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_ORG_CHART, getOrgChartList);
}

export default function* orgChartSaga() {
  yield all([watchPendingSagas()]);
}
