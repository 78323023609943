import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { getSessionStore } from "app/utils/sessionStore";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";

export const RESET_PASSWORD_EMAIL = "RESET_PASSWORD_EMAIL";
export const RESET_PASSWORD_PHONE = "RESET_PASSWORD_PHONE";
export const VERIFY_TOKEN_EMAIL = "VERIFY_TOKEN_EMAIL";
export const VERIFY_TOKEN_PHONE = "VERIFY_TOKEN_PHONE";
export const UPDATE_PASSWORD_EMAIL = "UPDATE_PASSWORD_EMAIL";
export const UPDATE_PASSWORD_PHONE = "UPDATE_PASSWORD_PHONE";
export const CREATE_NEW_PASSWORD_LINK = "CREATE_NEW_PASSWORD_LINK";
export const RESET_PASSWORD_LINK = "RESET_PASSWORD_LINK";
export const UPDATE_FCM_TOKEN = "UPDATE_FCM_TOKEN";

interface OtpMetaData {
  otpMode: string;
  phoneNumber?: null | string;
  email?: null | string;
  countryCode?: null | string;
}

interface ResponseType {
  isSuccess: boolean;
  message?: string;
}
interface ForgotPassword {
  token: string;
  otpMetaData: OtpMetaData;
  tokenGenerationSuccess: ResponseType;
  tokenVerificationSuccess: ResponseType;
  createPasswordSuccess: ResponseType;
  resetLink?: boolean | null;
}

const forgotPwd = getSessionStore("forgotPwdUserInfo");

const initialState: ForgotPassword = Object.keys(forgotPwd).length
  ? forgotPwd
  : {
      token: "",
      otpMetaData: { otpMode: "email" },
      tokenGenerationSuccess: { isSuccess: false, message: "" },
      tokenVerificationSuccess: { isSuccess: false, message: "" },
      createPasswordSuccess: { isSuccess: false, message: "" },
      resetLink: null,
    };

export const forgotPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setOtpMetaData: (state, action: PayloadAction<OtpMetaData>) => {
      state.otpMetaData = action.payload;
    },
    toggleTokenSuccess: (state, action: PayloadAction<ResponseType>) => {
      state.tokenGenerationSuccess = action.payload;
    },
    toggleTokenVerificationSuccess: (state, action: PayloadAction<ResponseType>) => {
      state.tokenVerificationSuccess = action.payload;
    },
    toggleUpdatePasswordSuccess: (state, action: PayloadAction<ResponseType>) => {
      state.createPasswordSuccess = action.payload;
    },
    resetTemporaryState: (state) => {
      state.otpMetaData = initialState.otpMetaData;
      state.tokenGenerationSuccess = { isSuccess: false, message: "" };
      state.tokenVerificationSuccess = { isSuccess: false, message: "" };
      state.createPasswordSuccess = { isSuccess: false, message: "" };
      state.resetLink = null;
    },
    resetPasswordLinkSuccess: (state, action: PayloadAction<boolean | null>) => {
      state.resetLink = action.payload;
    },
    resetPasswordResponseError: (state: any) => {
      state.createPasswordSuccess = { isSuccess: false, message: "" };
    },
  },
});

export const {
  setToken,
  setOtpMetaData,
  toggleTokenSuccess,
  toggleTokenVerificationSuccess,
  toggleUpdatePasswordSuccess,
  resetTemporaryState,
  resetPasswordLinkSuccess,
  resetPasswordResponseError,
} = forgotPasswordSlice.actions;
export const selectProducts = (state: RootState) => state.resetPassword;

export default forgotPasswordSlice.reducer;
