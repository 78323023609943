import {
  REMOVE_TEAM_MEMBER,
  removeTeamMemberError,
  removeTeamMemberStart,
  removeTeamMemberSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* removeTeamMemberRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(removeTeamMemberStart());
    const response = yield call(() => makeRequest(address).delete(key, requestParams, queryParams));
    yield put(removeTeamMemberSuccess(response.data));
  } catch (error: any) {
    yield put(removeTeamMemberError(error));
  }
}

function* watchRemoveTeamMemberSaga() {
  yield takeLatest(REMOVE_TEAM_MEMBER, removeTeamMemberRequest);
}
export default function* removeTeamMemberSaga() {
  yield all([watchRemoveTeamMemberSaga()]);
}
