import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const VALIDATE_INVITE = "VALIDATE_INVITE";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const validateInviteSlice = createSlice({
  name: "validateInvite",
  initialState,
  reducers: {
    validateInviteStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    validateInviteSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    validateInviteError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetValidateInviteState: () => initialState,
  },
});

export const {
  validateInviteStart,
  validateInviteSuccess,
  validateInviteError,
  resetValidateInviteState,
} = validateInviteSlice.actions;

export const validateInvite = (state: RootState) => state.validateInvite;
export default validateInviteSlice.reducer;
