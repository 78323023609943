import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_INSURANCE_COMPANY = "TRIGGER_GET_INSURANCE_COMPANY";
export const TRIGGER_POST_INSURANCE_COMPANY = "TRIGGER_POST_INSURANCE_COMPANY";
export const TRIGGER_DELETE_INSURANCE_COMPANY = "TRIGGER_DELETE_INSURANCE_COMPANY";
export const TRIGGER_PUT_INSURANCE_COMPANY = "TRIGGER_PUT_INSURANCE_COMPANY";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface insuranceCompanyInterface {
  insuranceCompanyData: any;
  insuranceCompanyLoading: boolean;
  insuranceCompanyError: any;
  insuranceCompanyPostData: any;
  insuranceCompanyPostError: any;
  insuranceCompanyPostLoading: boolean;
  insuranceCompanyDeleteData: any;
  insuranceCompanyDeleteError: any;
  insuranceCompanyDeleteLoading: boolean;
  insuranceCompanyPutData?: InitialStateInterface;
}

const initialState: insuranceCompanyInterface = {
  insuranceCompanyData: {},
  insuranceCompanyLoading: false,
  insuranceCompanyError: "",
  insuranceCompanyPostData: {},
  insuranceCompanyPostError: "",
  insuranceCompanyPostLoading: false,
  insuranceCompanyDeleteData: {},
  insuranceCompanyDeleteError: "",
  insuranceCompanyDeleteLoading: false,
  insuranceCompanyPutData: { data: {}, error: {}, loading: false },
};

export const insuranceCompanySlice = createSlice({
  name: "insuranceCompany",
  initialState,
  reducers: {
    insuranceCompanySuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyData = action.payload;
      state.insuranceCompanyError = "";
      state.insuranceCompanyLoading = false;
    },
    insuranceCompanyError: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyData = {};
      state.insuranceCompanyError = action.payload;
      state.insuranceCompanyLoading = false;
    },
    insuranceCompanyLoading: (state) => {
      state.insuranceCompanyData = {};
      state.insuranceCompanyError = {};
      state.insuranceCompanyLoading = true;
    },
    insuranceCompanyPostSuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyPostData = action.payload;
      state.insuranceCompanyPostError = {};
      state.insuranceCompanyPostLoading = false;
    },
    insuranceCompanyPostError: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyPostData = {};
      state.insuranceCompanyPostError = action.payload;
      state.insuranceCompanyPostLoading = false;
    },
    insuranceCompanyPostLoading: (state) => {
      state.insuranceCompanyPostData = {};
      state.insuranceCompanyPostError = {};
      state.insuranceCompanyPostLoading = true;
    },
    insuranceCompanyDeleteSuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyDeleteData = action.payload;
      state.insuranceCompanyDeleteError = {};
      state.insuranceCompanyDeleteLoading = false;
    },
    insuranceCompanyDeleteError: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyDeleteData = {};
      state.insuranceCompanyDeleteError = action.payload;
      state.insuranceCompanyDeleteLoading = false;
    },
    insuranceCompanyDeleteLoading: (state) => {
      state.insuranceCompanyDeleteData = {};
      state.insuranceCompanyDeleteError = {};
      state.insuranceCompanyDeleteLoading = true;
    },
    resetInsuranceCompany: (state) => {
      state.insuranceCompanyPostData = {};
      state.insuranceCompanyPostError = "";
      state.insuranceCompanyPostLoading = false;
      state.insuranceCompanyDeleteData = {};
      state.insuranceCompanyDeleteError = "";
      state.insuranceCompanyDeleteLoading = false;
      state.insuranceCompanyPutData = { data: {}, error: {}, loading: false };
    },
    insuranceCompanyPutStart: (state) => {
      state.insuranceCompanyPutData = { data: {}, error: {}, loading: true };
    },
    insuranceCompanyPutSuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyPutData = { data: action.payload, error: {}, loading: false };
    },
    insuranceCompanyPutError: (state, action: PayloadAction<any[]>) => {
      state.insuranceCompanyPutData = { data: {}, error: action.payload, loading: false };
    },
  },
});

export const {
  insuranceCompanySuccess,
  insuranceCompanyError,
  insuranceCompanyLoading,
  insuranceCompanyDeleteError,
  insuranceCompanyPostSuccess,
  insuranceCompanyPostError,
  insuranceCompanyPostLoading,
  insuranceCompanyDeleteSuccess,
  insuranceCompanyDeleteLoading,
  insuranceCompanyPutStart,
  insuranceCompanyPutSuccess,
  insuranceCompanyPutError,
  resetInsuranceCompany,
} = insuranceCompanySlice.actions;
export const getInsuranceCompanyState = (state: RootState) => state.insuranceCompany;

export default insuranceCompanySlice.reducer;
