import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PrioritiesStepperState {
  activeIndex: number | string;
}

const initialState: PrioritiesStepperState = {
  activeIndex: "",
};

const prioritiesStepperSlice: any = createSlice({
  name: "prioritiesStepper",
  initialState,
  reducers: {
    setActiveIndex: (state, action: PayloadAction<any>) => {
      state.activeIndex = action.payload;
    },
  },
});

export const { setActiveIndex } = prioritiesStepperSlice.actions;

export default prioritiesStepperSlice.reducer;
