import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_ORG_CHART = "GET_ORG_CHART";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface orgChartFetchResponseType {
  fetchOrgChart: InitialStateInterface;
}

const initialState: orgChartFetchResponseType = {
  fetchOrgChart: { data: {}, error: {}, loading: false },
};

export const orgChartSlice = createSlice({
  name: "orgChart",
  initialState,
  reducers: {
    fetchOrgChartLoading: (state) => {
      state.fetchOrgChart = { data: {}, error: {}, loading: true };
    },
    fetchOrgChartSuccess: (state, action: PayloadAction<any>) => {
      state.fetchOrgChart = { data: action.payload.data, error: {}, loading: false };
    },
    fetchOrgChartError: (state, action: PayloadAction<any>) => {
      state.fetchOrgChart = { data: {}, error: action.payload, loading: false };
    },
    resetFetchOrgChartState: (state) => {
      state.fetchOrgChart = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchOrgChartLoading,
  fetchOrgChartSuccess,
  fetchOrgChartError,
  resetFetchOrgChartState,
} = orgChartSlice.actions;
export const getOrgChart = (state: RootState) => state.orgChart;

export default orgChartSlice.reducer;
