import {
  TRIGGER_GET_BRANDING_LIST,
  TRIGGER_GET_CASE_MANAGER,
  TRIGGER_INVITE_AGENT,
  setBrandingFailure,
  setBrandingOngoing,
  setBrandingSuccess,
  setCaseManagerFailure,
  setCaseManagerOngoing,
  setCaseManagerSuccess,
  setInviteAgencyFailure,
  setInviteAgencyOngoing,
  setInviteAgencySuccess,
  setInviteAgentFailure,
  setInviteAgentOngoing,
  setInviteAgentSuccess,
} from "app/redux/features/agency-invite";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendInvitesToAgency(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(setInviteAgencyOngoing());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(setInviteAgencySuccess(response.data));
  } catch (e: any) {
    yield put(setInviteAgencyFailure(e));
  }
}

function* getBrandingList(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setBrandingOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setBrandingSuccess(response.data));
  } catch (e: any) {
    yield put(setBrandingFailure(e));
  }
}

function* getCaseManagerList(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setCaseManagerOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setCaseManagerSuccess(response.data));
  } catch (e: any) {
    yield put(setCaseManagerFailure(e));
  }
}

function* sendInvitesToAgent(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(setInviteAgentOngoing());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(setInviteAgentSuccess(response.data));
  } catch (e: any) {
    yield put(setInviteAgentFailure(e));
  }
}

function* watchAgencyInvites() {
  yield takeLatest("TRIGGER_INVITE_AGENCY", sendInvitesToAgency);
  yield takeLatest(TRIGGER_GET_BRANDING_LIST, getBrandingList);
  yield takeLatest(TRIGGER_GET_CASE_MANAGER, getCaseManagerList);
  yield takeLatest(TRIGGER_INVITE_AGENT, sendInvitesToAgent);
}

export default function* inviteAgencySaga() {
  yield all([watchAgencyInvites()]);
}
