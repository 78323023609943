import {
  getAgentProfileStart,
  getAgentProfileSuccess,
  getAgentProfileError,
  GET_AGENT_PROFILE,
} from "@reducers";
import {
  CONVERT_TO_AGENT,
  UPDATE_SESSION_COUNT,
  convertAgentError,
  convertAgentStart,
  convertAgentSuccess,
  updateSessionUpdateCount,
} from "app/redux/features/agent-profile";
import { makeRequest } from "app/utils/makeRequest";
import { setSessionStore } from "app/utils/sessionStore";
import { call, put, takeLatest } from "redux-saga/effects";

function* getAgentProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getAgentProfileStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    response?.data?.subscription && setSessionStore("currentPlan", response?.data?.subscription);

    yield put(getAgentProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getAgentProfileError(error));
  }
}

function* convertAgentProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(convertAgentStart());
    const response = yield call(() => makeRequest(path).put(key, {}, requestParams, queryParams));

    // const response = yield call(() => makeRequest(path).put(key, requestParams));

    yield put(convertAgentSuccess(response.data));
  } catch (error: any) {
    yield put(convertAgentError(error));
  }
}

function* updateSessionCount(): Generator<any, void, any> {
  yield put(updateSessionUpdateCount());
}

export default function* watchAgentProfile() {
  yield takeLatest(GET_AGENT_PROFILE, getAgentProfile);
  yield takeLatest(CONVERT_TO_AGENT, convertAgentProfile);
  yield takeLatest(UPDATE_SESSION_COUNT, updateSessionCount);
}
