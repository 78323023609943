import { payload } from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchContactSummaryStart,
  fetchContactSummarySuccess,
  fetchContactSummaryFailure,
  TRIGGER_CONTACT_SUMMARY,
} from "app/redux";

function* getContactsSummary(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(fetchContactSummaryStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchContactSummarySuccess(response.data));
  } catch (error: any) {
    yield put(fetchContactSummaryFailure(error));
  }
}

function* watchContactSummarySagas() {
  yield takeLatest(TRIGGER_CONTACT_SUMMARY, getContactsSummary);
}

export default function* contactSummarySaga() {
  yield all([watchContactSummarySagas()]);
}
