import {
  DELETE_SUB_AGENT_ROLE,
  GET_SA_AGENCY_AGENTS,
  GET_SA_AGENCY_ASSISTANTS,
  GET_SA_AGENCY_MANAGERS,
  PATCH_SA_AGENT_ROLE,
  getAgentsError,
  getAgentsStart,
  getAgentsSuccess,
  getAssistantsError,
  getAssistantsStart,
  getAssistantsSuccess,
  getManagersError,
  getManagersStart,
  getManagersSuccess,
  patchAgentsRoleError,
  patchAgentsRoleStart,
  patchAgentsRoleSuccess,
  subAgentRemoveError,
  subAgentRemoveStart,
  subAgentRemoveSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* saAdminAgencyManagerRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getManagersStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getManagersSuccess(response.data));
  } catch (error: any) {
    yield put(getManagersError(error));
  }
}
function* saAdminAGentsRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getAgentsStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));

    yield put(getAgentsSuccess(response.data));
  } catch (error: any) {
    yield put(getAgentsError(error));
  }
}
function* saAdminAgencyAssistantsRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getAssistantsStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getAssistantsSuccess(response.data));
  } catch (error: any) {
    yield put(getAssistantsError(error));
  }
}

function* patchAgentRole(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(patchAgentsRoleStart());
    const response = yield call(() => makeRequest(path).patch(requestBody));
    yield put(patchAgentsRoleSuccess(response.data));
  } catch (error: any) {
    yield put(patchAgentsRoleError(error));
  }
}

function* deleteAgentsAssistant(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(subAgentRemoveStart());
    const response = yield call(() => makeRequest(path).deleteWithBody(body));
    yield put(subAgentRemoveSuccess(response.data));
  } catch (error: any) {
    yield put(subAgentRemoveError(error));
  }
}

function* watchSaAgencyRolesSagas() {
  yield takeLatest(GET_SA_AGENCY_MANAGERS, saAdminAgencyManagerRequest);
  yield takeLatest(GET_SA_AGENCY_ASSISTANTS, saAdminAgencyAssistantsRequest);
  yield takeLatest(GET_SA_AGENCY_AGENTS, saAdminAGentsRequest);
  yield takeLatest(PATCH_SA_AGENT_ROLE, patchAgentRole);
  yield takeLatest(DELETE_SUB_AGENT_ROLE, deleteAgentsAssistant);
}

export default function* saAgencyRolesSagas() {
  yield all([watchSaAgencyRolesSagas()]);
}
